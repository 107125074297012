import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import StatsSection from '../../components/stats/StatsSection';
import AdvancedTable from '../../components/tracker/AdvancedTable';

const AllHabitsView = () => {
  const { state } = useLocation();
  const [table, setTable] = useState('advanced');

  useEffect(() => {
    if (state) {
      if (state.reviewTime) {
        setTable('review');
      }
    }
  }, []);

  return (
    <>
      <StatsSection goToReview={() => setTable('review')} />
      <AdvancedTable table={table} />
    </>
  );
};

export default AllHabitsView;
