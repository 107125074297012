import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import ProtectedRoute from './ProtectedRoute';

import ErrorLayout from './ErrorLayout';
import loadable from '@loadable/component';

import MyDay from '../views/MyDay';
import AllHabits from '../views/AllHabits';
import Plans from '../views/Plans';
import Messages from '../views/Messages/index';
import Settings from '../components/Settings/Settings';
import { PartnerInviteModal } from '../components/PartnerInvite';
import { useAuth } from '../context/AuthContext';

const AuthCardRoutes = loadable(() => import('../components/auth/AuthCardRoutes'));

const Layout = () => {
  const { invite } = useAuth();

  const [isOpen, setIsOpen] = useState(!!invite);

  useEffect(() => {
    AuthCardRoutes.preload();
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/authentication" component={AuthCardRoutes} />
        <Route path="/errors" component={ErrorLayout} />
        <ProtectedRoute path="/myday" exact component={<MyDay />} />
        <ProtectedRoute path="/allhabits" exact component={<AllHabits />} />
        <ProtectedRoute path="/plans" exact component={<Plans />} />
        <ProtectedRoute path="/settings" exact component={<Settings />} />
        <ProtectedRoute path="/messages/:userId" component={<Messages />} />
        <ProtectedRoute path="/messages/" component={<Messages />} />
        <Redirect to="/myday" />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.TOP_CENTER} />
      {invite && <PartnerInviteModal isOpen={isOpen} toggleOpen={() => setIsOpen(!isOpen)} invite={invite} />}
    </Router>
  );
};

export default Layout;
