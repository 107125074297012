import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { breakpoints } from '../../helpers/utils';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import AppContext from '../../context/Context';
import { useData } from '../../context/DataContext';
import { NewMessageIndicator } from './NewMessageIndicator';

const NavbarTopDropDownMenus = ({ setNavbarCollapsed, setShowBurgerMenu }) => {
  const { isCombo, isTopNav } = useContext(AppContext);

  const { unreadCount } = useData();

  const handleSetNavbarCollapsed = pageName => {
    const windowWidth = window.innerWidth;
    isTopNav && !isCombo && windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
    isCombo && windowWidth < breakpoints[navbarBreakPoint] && setShowBurgerMenu(false);
    window.analytics.track(`Clicked on "${pageName}"`);
  };
  return (
    <div className="mt-2 d-flex justify-content-center align-items-center">
      <NavItem
        onClick={() => handleSetNavbarCollapsed('My Day')}
        className="d-flex justify-content-center align-items-center mx-4 "
      >
        <NavLink className="nav-link font-weight-normal" to="/myday" activeClassName="font-weight-bold">
          My Day
        </NavLink>
      </NavItem>
      <NavItem
        onClick={() => handleSetNavbarCollapsed('Manage All Habits')}
        className="d-flex justify-content-center align-items-center mx-4 "
      >
        <NavLink className="nav-link font-weight-normal" to="/allhabits" activeClassName="font-weight-bold">
          Manage All Habits
        </NavLink>
      </NavItem>
      <NavItem
        onClick={() => handleSetNavbarCollapsed('Messages')}
        className="d-flex justify-content-center align-items-center mx-4 "
      >
        <NavLink
          className="nav-link font-weight-normal d-flex align-items-start"
          to="/messages"
          activeClassName="font-weight-bold"
        >
          Messages <NewMessageIndicator unreadCount={unreadCount} />
        </NavLink>
      </NavItem>
      <NavItem
        onClick={() => handleSetNavbarCollapsed('Reports')}
        className="d-flex justify-content-center align-items-center"
      >
        <p className="nav-link mb-0 font-weight-normal">
          Reports <span className="fs--2"> (Coming Soon)</span>
        </p>
      </NavItem>
    </div>
  );
};

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };

export default NavbarTopDropDownMenus;
