import React, { useState } from 'react';

import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { useAuth } from '../../context/AuthContext';

import * as S from './styled';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import api from '../../services/api';

export default function VerifyAccount() {
  const { user } = useAuth();

  const [alreadyClicked, setAlreadyClicked] = useState(false);

  const resendVerifyEmail = async () => {
    try {
      if (!alreadyClicked) {
        await api.get('/send-verify');
        setAlreadyClicked(true);
      }
      return toast.info('Email sent. Please check your inbox.');
    } catch (err) {
      console.log(err);
    }
  };

  if (!user.verified) {
    return (
      <S.Wrapper>
        Your email is not verified. Please check your inbox and verify your email.{' '}
        <Button color="link" onClick={resendVerifyEmail}>
          <FontAwesomeIcon icon={faEnvelope} className="mr-1" />I didn't receive the email
        </Button>{' '}
      </S.Wrapper>
    );
  } else {
    return null;
  }
}
