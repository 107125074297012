import React from 'react';

import * as S from './styled';

const ProgressScore = ({ table, week, isForReviewTable }) => {
  const { score, improvement, improvementColor, hasReviews } = week;

  if (typeof score !== 'number' && !isForReviewTable) {
    return <S.Container table={table} />;
  }

  if (!hasReviews) {
    return <S.Container table={table} />;
  }

  return (
    <S.Container className="progress-score" table={table} isForReviewTable={isForReviewTable}>
      <div className="d-flex flex-column text-center">
        <S.Score>
          <span>{score.toFixed(0)}</span>/100
        </S.Score>
        <S.Improvement style={{ color: improvementColor }}>
          {improvement.number > 0 && '+'}
          {improvement.number}
          {improvement.style}
        </S.Improvement>
      </div>
    </S.Container>
  );
};

export default ProgressScore;
