import { toast } from 'react-toastify';
import api from '../services/api';
import moment from 'moment';
import { getWeekYear } from './tracker-parsers/helpers';

const firstDaysOfWeek = Number(moment().format('d')) === 1 || Number(moment().format('d')) === 2;

const payload = [
  {
    habit: {
      name: 'Fill the tracker every week',
      frequency:
        '{"duration":1,"repeat":{"type":"weekly","days":[],"times":1},"time":{"value":1,"period":"unspecified"}}',
      motivation: 'Test',
      category_id: 3,
      priority: 'high',
      active: true,
      sample: true,
    },
    reviews: [
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -1 : 0 }), score: 4 },
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -2 : -1 }), score: 4 },
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -3 : -2 }), score: 4 },
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -4 : -3 }), score: 4 },
    ],
  },
  {
    habit: {
      name: 'Running',
      frequency:
        '{"duration":30,"repeat":{"type":"weekly","days":[],"times":3},"time":{"value":1,"period":"unspecified"}}',
      motivation: 'Test',
      category_id: 4,
      priority: 'high',
      active: true,
      sample: true,
    },
    reviews: [
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -1 : 0 }), score: 4 },
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -2 : -1 }), score: 3 },
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -3 : -2 }), score: 2 },
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -4 : -3 }), score: 1 },
    ],
  },
  {
    habit: {
      name: 'Meditation',
      frequency:
        '{"duration":10,"repeat":{"type":"weekly","days":[],"times":1},"time":{"value":1,"period":"unspecified"}}',
      motivation: 'Test',
      category_id: 5,
      priority: 'high',
      active: true,
      sample: true,
    },
    reviews: [
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -1 : 0 }), score: 0 },
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -2 : -1 }), score: 3 },
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -3 : -2 }), score: 1 },
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -4 : -3 }), score: 2 },
    ],
  },
  {
    habit: {
      name: 'Read',
      frequency:
        '{"duration":20,"repeat":{"type":"weekly","days":[],"times":7},"time":{"value":1,"period":"unspecified"}}',
      motivation: 'Test',
      category_id: 1,
      priority: 'high',
      active: true,
      sample: true,
    },
    reviews: [
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -1 : 0 }), score: 4 },
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -2 : -1 }), score: 3 },
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -3 : -2 }), score: 2 },
      { weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -4 : -3 }), score: 4 },
    ],
  },
];

export default async function(token) {
  for (const el of payload) {
    try {
      await api.post('/habit', el, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      toast.error('Error', { position: 'top-center' });
    }
  }
}
