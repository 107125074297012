import React from 'react';
import { useTable } from '../../../context/TableContext';
import Greetings from '../Greetings';
import StatsCard from '../StatsCard';
import * as S from './styled';

const StatsSection = ({ goToReview }) => {
  const { weeks, hasHabits } = useTable();

  const calculateValue = value => {
    const weeksWithScore = weeks.filter(w => w.hasReviews);
    const numOfWeeks = weeksWithScore.length;
    const lastWeek = weeksWithScore[numOfWeeks - 1];

    if (value === 'score') {
      return lastWeek ? lastWeek.score : 0;
    }

    if (value === 'improvement') {
      return lastWeek ? lastWeek.improvement : 0;
    }
  };

  return (
    <S.Container>
      <S.GreetingsWrapper>
        <Greetings goToReview={goToReview} />
      </S.GreetingsWrapper>
      <S.CardsWrapper>
        <StatsCard
          name="week"
          score={hasHabits && !!weeks.length ? calculateValue('score') : '00'}
          improvement={hasHabits && !!weeks.length ? calculateValue('improvement') : '00'}
        />
      </S.CardsWrapper>
    </S.Container>
  );
};

export default StatsSection;
