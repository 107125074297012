import moment from 'moment';
import { getCurrentUsersWeek, getWeekYear, calculateFirstReview } from './helpers';

export const weekYearToObj = wy => ({ week: wy.substring(1, 3), year: wy.substring(4, 6) });

export const roundScore = score => {
  const fromZeroToFour = score / 25;
  return Math.round(fromZeroToFour);
};

export const statusCalculator = habitReviews => {
  const reviewsWithScore = habitReviews.filter(r => typeof r.score === 'number');
  const reviews = reviewsWithScore;

  if (reviews.length === 0 || reviews.length === 1) {
    return 'On track';
  }

  const scores = reviews
    .sort((a, b) => weekYearToNumber(b.weekyear) - weekYearToNumber(a.weekyear))
    .map(review => Number(review.score))
    .slice(0, 4);

  const currentScore = scores[0];

  if (reviews.length === 2) {
    if (currentScore > 0) return 'On track';
    return 'Attention';
  }

  const previousScore = scores[1];
  const varianceLastWeek = currentScore - previousScore;

  if (reviews.length === 3) {
    if (varianceLastWeek >= -2 && currentScore > 0) {
      return 'On track';
    }
    return 'Attention';
  }

  const averageLastThree = scores.slice(1).reduce((a, b) => a + b) / 3;

  const varianceFromTheAvgLastThreeWeeks = currentScore - averageLastThree;

  if (reviews.length === 4) {
    if (varianceLastWeek >= -2 && currentScore > 0 && varianceFromTheAvgLastThreeWeeks >= -2) {
      return 'On track';
    }
    return 'Attention';
  }

  const averageLastFour = scores.reduce((a, b) => a + b) / 4;

  if (averageLastFour > 9) {
    return 'Matured';
  } else if (varianceLastWeek >= -2 && currentScore > 0 && varianceFromTheAvgLastThreeWeeks > -2) {
    return 'On track';
  } else if (averageLastFour < 1) {
    return 'At risk';
  } else if (varianceLastWeek <= 0 || varianceFromTheAvgLastThreeWeeks < 0) {
    return 'Attention';
  }
};

export const weekYearToNumber = wy => {
  const week = wy.substring(1, 3);
  const year = wy.substring(4, 6);

  const inverted = moment()
    .isoWeekYear(year)
    .isoWeek(week)
    .format('GGWW');

  return parseInt(inverted);
};

export const weekYearToString = wy => {
  const parsed = wy.toString();
  const week = parsed.substring(2, 4);
  const year = parsed.substring(0, 2);

  const inverted = moment()
    .isoWeekYear(year)
    .isoWeek(week)
    .format('[W]WW[Y]GG');

  return inverted;
};

export const momentToWeekYear = (momentObj, addOneDay) => {
  const newMoment = moment(momentObj).add(addOneDay ? 1 : 0, 'days');

  const weekyear = newMoment.format('[W]WW[Y]GG');

  return weekyear;
};

export const parseDayOfWeek = (num, triple) => {
  if (num === 1 && triple) return 'Mon';
  if (num === 2 && triple) return 'Tue';
  if (num === 3 && triple) return 'Wed';
  if (num === 4 && triple) return 'Thu';
  if (num === 5 && triple) return 'Fri';
  if (num === 6 && triple) return 'Sat';
  if (num === 7 && triple) return 'Sun';

  if (num === 1) return 'M';
  if (num === 2) return 'T';
  if (num === 3) return 'W';
  if (num === 4) return 'Th';
  if (num === 5) return 'F';
  if (num === 6) return 'Sa';
  if (num === 7) return 'Su';
};

export const parsePeriodStartHour = period => {
  if (period === 'Early morning') return '06:00';
  if (period === 'Morning') return '09:00';
  if (period === 'Afternoon') return '12:00';
  if (period === 'Late afternoon') return '15:00';
  if (period === 'Evening') return '18:00';
  if (period === 'Late evening') return '21:00';
};

const parsePeriodEndHour = period => {
  if (period === 'Early morning') return '09:00';
  if (period === 'Morning') return '12:00';
  if (period === 'Afternoon') return '15:00';
  if (period === 'Late afternoon') return '18:00';
  if (period === 'Evening') return '21:00';
  if (period === 'Late evening') return '00:00';
};

const momentTimeFormatter = (data, type, addMinutes) => {
  const momentObject = moment(data, [moment.ISO_8601, 'HH:mm']);
  return momentObject.add(addMinutes ? addMinutes : 0, 'minutes').format(type);
};

export const frequencyToHour = (frequency, notFormmated) => {
  const { time, duration } = frequency;

  if (time.value > 1) return 'Several times today';

  if (time.period === 'unspecified') return 'Anytime today';

  if (time.period === 'exact') {
    if (notFormmated) {
      return {
        start: time.start,
        end: momentTimeFormatter(time.start, 'HH:mm', Number(duration)),
      };
    }

    const hours = momentTimeFormatter(time.start, 'hh:mm');
    const ampm = momentTimeFormatter(time.start, 'a');

    const hoursEnd = momentTimeFormatter(time.start, 'hh:mm', Number(duration));
    const ampmEnd = momentTimeFormatter(time.start, 'a', Number(duration));

    return `@ ${hours}${ampm !== ampmEnd ? ampm : ''} to ${hoursEnd}${ampmEnd}`;
  }

  if (time.period === 'range') {
    if (notFormmated) {
      return {
        start: time.start,
        end: time.end,
      };
    }

    const hours = momentTimeFormatter(time.start, 'hh:mm');
    const ampm = momentTimeFormatter(time.start, 'a');

    const hoursEnd = momentTimeFormatter(time.end, 'hh:mm');
    const ampmEnd = momentTimeFormatter(time.end, 'a');

    return `@ ${hours}${ampm !== ampmEnd ? ampm : ''} to ${hoursEnd}${ampmEnd}`;
  }

  if (notFormmated) {
    if (time.period === 'Late evening') {
      return {
        start: momentTimeFormatter(parsePeriodStartHour(time.period), 'HH:mm'),
        end: momentTimeFormatter(parsePeriodEndHour(time.period), 'HH:mm', -1),
      };
    }

    return {
      start: momentTimeFormatter(parsePeriodStartHour(time.period), 'HH:mm'),
      end: momentTimeFormatter(parsePeriodEndHour(time.period), 'HH:mm'),
    };
  }

  const hours = momentTimeFormatter(parsePeriodStartHour(time.period), 'hh:mm');
  const ampm = momentTimeFormatter(parsePeriodStartHour(time.period), 'a');

  const hoursEnd = momentTimeFormatter(parsePeriodEndHour(time.period), 'hh:mm');
  const ampmEnd = momentTimeFormatter(parsePeriodEndHour(time.period), 'a');

  return `@ ${hours}${ampm !== ampmEnd ? ampm : ''} to ${hoursEnd}${ampmEnd}`;
};

export const parseHabitPeriod = frequency => {
  const time = frequencyToHour(frequency, true);

  if (time === 'Anytime today' || time === 'Several times today') return { title: 'now', color: 'grey' };

  const now = moment(moment().format('HH:mm'), [moment.ISO_8601, 'HH:mm']);
  const start = moment(time.start, [moment.ISO_8601, 'HH:mm']);
  const end = moment(time.end, [moment.ISO_8601, 'HH:mm']);

  if (now.isBetween(start, end, null, '[]')) {
    return { title: 'now', color: 'red', prefix: 'Due now' };
  }

  if (start.isBetween(now, moment(now, [moment.ISO_8601, 'HH:mm']).add(3, 'hours'), null, '[]')) {
    return { title: 'now', color: 'orange', prefix: 'Get ready' };
  }

  if (end.isBefore(now)) {
    return { title: 'previous', color: 'grey', prefix: 'Past due' };
  }

  return { title: 'later', color: 'grey', prefix: 'Later' };
};

export const parseFrequency = (frequency, timesOnly) => {
  const { duration, repeat, time } = frequency;

  if (timesOnly && repeat.times === 0) {
    return 'times per week';
  }

  let str = [];

  if (repeat.type === 'weekly' || timesOnly) {
    if (repeat.times > 1 && repeat.times < 7) {
      str.push(`${repeat.times}x a week`);
    } else if (repeat.times === 1) {
      str.push('Once a week');
    } else {
      str.push('Every day');
    }
  }

  if (timesOnly) return str;

  if (repeat.type === 'everyday') {
    str.push('Every day');
  }

  if (repeat.type === 'daily') {
    let days = [];
    if (repeat.days.length < 3) {
      repeat.days.map(d => {
        days.push(parseDayOfWeek(d, true));
      });
    } else {
      repeat.days.map(d => {
        days.push(parseDayOfWeek(d));
      });
    }
    str.push('Every ');
    if (repeat.days.length === 2) {
      str.push(days.join(' and '));
    } else {
      str.push(days.join('-'));
    }
  }

  if (duration !== 0) {
    const parsedDuration = duration < 60 ? `${duration}min` : `${duration / 60}hr`;
    str.push(` for ${parsedDuration}`);
  }

  if (time.period === 'exact') {
    const hours = momentTimeFormatter(time.start, 'hh');
    const minutes = momentTimeFormatter(time.start, 'mm');
    const ampm = momentTimeFormatter(time.start, 'a');
    str.push(` @${hours}${minutes !== '00' ? `:${minutes}` : ''}${ampm}`);
  } else if (time.period === 'range') {
    const hours = momentTimeFormatter(time.start, 'hh');
    const minutes = momentTimeFormatter(time.start, 'mm');
    const ampm = momentTimeFormatter(time.start, 'a');

    const hoursEnd = momentTimeFormatter(time.end, 'hh');
    const minutesEnd = momentTimeFormatter(time.end, 'mm');
    const ampmEnd = momentTimeFormatter(time.end, 'a');

    str.push(
      ` @${hours}${minutes !== '00' ? `:${minutes}` : ''}${ampm !== ampmEnd ? ampm : ''}-${hoursEnd}${
        minutesEnd !== '00' ? `:${minutesEnd}` : ''
      }${ampmEnd}`
    );
  } else if (time.period === 'unspecified') {
  } else {
    str.push(` - ${time.period}`);
  }

  return str.join('');
};

export const calculateHint = (score, maxTimes) => {
  if (score === false || score === null) return '';

  const avg = ((maxTimes / 4) * score).toFixed(1);
  const decimal = avg % 1;

  if (decimal === 0.5) {
    return ` (~${avg} out of ${maxTimes})`;
  }

  const rounded = Math.round(avg);
  return ` (~${rounded} out of ${maxTimes})`;
};

export const maxTimesHabit = habit => {
  let time, repeat;
  if (typeof habit.frequency === 'string') {
    let parsed = JSON.parse(habit.frequency);
    time = parsed.time;
    repeat = parsed.repeat;
  } else {
    time = habit.frequency.time;
    repeat = habit.frequency.repeat;
  }
  const timesPerDay = time.value;
  let timesPerWeek;
  if (repeat.type === 'everyday') {
    timesPerWeek = 7;
  } else {
    timesPerWeek = repeat.type === 'weekly' ? repeat.times : repeat.days.length;
  }
  const maxTimesHabit = timesPerDay * timesPerWeek;
  return maxTimesHabit;
};

export const parseDateInfo = (data, settings) => {
  const { reviews, habits } = data;

  if (settings === null) {
    const momentFirstReview = calculateFirstReview(data, null);
    return {
      momentFirstReview,
    };
  }

  const userSettings = JSON.parse(settings);

  // getting last review
  const onlyDates = reviews.map(review => weekYearToNumber(review.weekyear)).sort((a, b) => a - b);
  const uniqueOnlyDates = [...new Set(onlyDates)];
  const firstReview = onlyDates[0];

  let lastReflection;

  if (userSettings.lastFilledReflection) {
    lastReflection = userSettings.lastFilledReflection;
  } else {
    const lastReviewDate = uniqueOnlyDates[uniqueOnlyDates.length - 1];

    const totalReviewsWithLastWeekDate = onlyDates.filter(date => date === lastReviewDate).length;
    const totalHabitsNotDeactivated = habits.filter(h => h.active).length;
    lastReflection =
      totalReviewsWithLastWeekDate >= totalHabitsNotDeactivated
        ? lastReviewDate
        : uniqueOnlyDates[uniqueOnlyDates.length - 2];
  }

  // current weekyear
  const currentWeekyear = getWeekYear();

  // day of the week
  const dayOfTheWeek = Number(moment().format('d'));

  // full Date
  const fullDate = moment().format('[Today is] MMM Do YYYY');

  const currentUsersWeek = getCurrentUsersWeek(currentWeekyear, weekYearToString(firstReview));

  // first review
  const momentFirstReview = calculateFirstReview(data, userSettings.start_day);

  return {
    lastReview: lastReflection,
    momentFirstReview,
    currentWeekyear,
    currentUsersWeek,
    dayOfTheWeek,
    fullDate,
  };
};

export const abrevTextParser = score => {
  if (score === 1) return 'L';
  if (score === 2) return 'ML';
  if (score === 3) return 'M';
  if (score === 4) return 'MH';
  if (score === 5) return 'H';
};

export const textParser = score => {
  if (score === false) return '';

  if (score === null) return 'Skip';
  if (score === 0) return 'None';
  if (score === 1) return 'Minimal';
  if (score === 2) return 'Average';
  if (score === 3) return 'Great';
  if (score === 4) return 'Excellent';
};

export const colorParser = (score, forLabel) => {
  if ((score === 2 || score === 'Attention') && forLabel) return `#ffc000`;

  if (score === null) return '#c4c4c4';
  if (score === 0) return '#e06666';
  if (score === 1 || score === 'At risk') return '#ea9999';
  if (score === 2 || score === 'Attention') return '#ffd966';
  if (score === 3 || score === 'On track') return '#b6d7a8';
  if (score === 4 || score === 'Matured') return '#93c47d';
};

export const liabilityToEmoji = liability => {
  if (liability === 'Uncategorized') return '➖';
  if (liability === 'Knowledge') return '📚';
  if (liability === 'Well-being') return '😌';
  if (liability === 'Earning') return '💰';
  if (liability === 'Giving') return '💸';
};

const priorityParser = priority => {
  if (priority === 'low') return 1;
  if (priority === 'medium') return 2;
  if (priority === 'high') return 3;
};

const getReviewsWithPriorities = (reviews, habits) => {
  const reviewWithPriorities = reviews
    .filter(r => typeof r.score === 'number' || r.score === null)
    .map(r => {
      const { priority } = habits.find(h => h.id === r.habit_id);
      const priorityInNumber = priorityParser(priority);
      const parsedScore = r.score * priorityInNumber;
      return { ...r, parsedScore };
    });

  return reviewWithPriorities;
};

const getSumOfMaxes = (reviews, habits) => {
  const sumOfMaxes = reviews
    .filter(r => typeof r.score === 'number')
    .map(r => {
      const { priority } = habits.find(h => h.id === r.habit_id);
      const priorityInNumber = priorityParser(priority);
      const max = priorityInNumber * 4;
      return max;
    });

  return sumOfMaxes;
};

export const calculateScore = (habits, reviewsArr, reviewInputs) => {
  const isForReviewTable = !!reviewInputs;

  if (isForReviewTable) {
    const allScoresEmpty = reviewInputs.filter(r => r.score !== false);
    if (!allScoresEmpty.length) return 0;

    const sumOfReviews = getReviewsWithPriorities(reviewInputs, habits)
      .map(r => r.parsedScore)
      .reduce((a, b) => a + b, 0);
    const sumOfMaxes = getSumOfMaxes(reviewInputs, habits).reduce((a, b) => a + b, 0);
    const score = (sumOfReviews / sumOfMaxes) * 100;

    return score;
  }

  const sumOfReviews = getReviewsWithPriorities(reviewsArr, habits)
    .map(r => r.parsedScore)
    .reduce((a, b) => a + b, 0);

  const sumOfMaxes = getSumOfMaxes(reviewsArr, habits).reduce((a, b) => a + b, 0);

  if (sumOfReviews === 0 && sumOfMaxes === 0) {
    return 0;
  }

  const score = (sumOfReviews / sumOfMaxes) * 100;

  return score;
};

export const calculateImprovement = (weekScore, lastWeekScore) => {
  if (typeof lastWeekScore !== 'number') {
    return 0;
  } else {
    const calc = (weekScore / lastWeekScore - 1) * 100;

    if (isNaN(calc)) {
      return {
        number: 0,
        style: '%',
      };
    } else if (calc === Infinity) {
      return {
        number: Math.round((weekScore / 1) * 10) / 10,
        style: 'x',
      };
    } else if (calc > 200) {
      return {
        number: Math.round((weekScore / lastWeekScore) * 10) / 10,
        style: 'x',
      };
    } else {
      return {
        number: Math.round(calc * 10) / 10,
        style: '%',
      };
    }
  }
};

export const improvementColor = improvement => {
  const { number } = improvement;
  if (number < -5) {
    return '#e06666';
  } else if (number >= -5 && number <= 5) {
    return '#ffd966';
  } else {
    return '#93c47d';
  }
};

/* this function works different than mobile version */
export const prepareScores = (weeks, reviews) => {
  const scores = [];
  for (const week of weeks) {
    const reviewOfWeek = reviews.find(review => review.weekyear === week.weekYear || review.weekyear === week.weekyear);
    if (reviewOfWeek) {
      scores.push(reviewOfWeek.score ? reviewOfWeek.score : -1);
    } else {
      scores.push(-1);
    }
  }

  return scores;
};
