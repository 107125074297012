import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';

import AppContext from '../context/Context';

import { TableProvider } from '../context/TableContext';

const DashboardLayout = ({ component }) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);
  const location = useLocation();

  const page = location.pathname.substring(1);

  useEffect(() => {
    const isInsideIframe = window === window.parent ? false : true;

    window &&
      window.analytics &&
      window.analytics.page(page, {
        referrer: isInsideIframe ? 'Chrome Extension' : document.referrer,
      });
  }, [location]);

  const shouldRenderFooter = page !== 'messages';

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {isVertical && <NavbarVertical navbarStyle={navbarStyle} />}
      <div className="content">
        <NavbarTop />
        <TableProvider>{component}</TableProvider>
        {shouldRenderFooter && <Footer />}
      </div>
    </div>
  );
};

export default DashboardLayout;
