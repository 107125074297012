import React from 'react';
import { Button, FormGroup, Modal, ModalBody, ModalFooter } from 'reactstrap';

import * as S from './styled';

export default function ReflectModal({
  reviewInput,
  handleChange,
  isOpen,
  toggleOpen,
  handleSave,
  dismissable,
  habitName,
  note,
  handleSubmit,
}) {
  const handleSaveAndToggle = () => {
    handleSave();
    toggleOpen();
  };

  return (
    <Modal
      isOpen={isOpen}
      keyboard={dismissable === true ? true : false}
      backdrop={dismissable === true ? true : 'static'}
      toggle={toggleOpen}
      centered
    >
      <S.Header>What are you going to do differently the upcoming week?</S.Header>
      <ModalBody>
        <FormGroup>
          <S.ReviewInput
            type="textarea"
            id="insights"
            name="insights"
            placeholder="E.g. Prepare my gym clothes in the morning."
            value={note}
            onChange={handleChange}
          />
        </FormGroup>
        <div className="text-muted fs--1">
          <i>PS. Your accountability partners will be able to see the notes you add here.</i>
        </div>
        {dismissable === false && (
          <div className="text-danger">
            You are required to add a note since <i className="font-weight-bold">{habitName}</i> is no longer on track
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggleOpen}>Close</Button>
        <Button color="primary" onClick={handleSave ? handleSaveAndToggle : handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
