import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useAuth } from '../../../context/AuthContext';
import { useData } from '../../../context/DataContext';
import { useTable } from '../../../context/TableContext';
import api from '../../../services/api';

import { getWeekYear, getLogsOfTheWeek, getReviewDaysRange } from '../../../helpers/tracker-parsers/helpers';
import {
  weekYearToNumber,
  colorParser,
  maxTimesHabit,
  calculateScore,
  improvementColor,
  calculateImprovement,
  prepareScores,
} from '../../../helpers/tracker-parsers/parsers';

import HabitName from '../HabitName';
import HabitScore from '../HabitScore';
import HabitReview from '../HabitReview';
import AddHabitRow from '../AddHabitRow';
import ProgressScore from '../ProgressScore';

import * as S from './styled';
import { StreakIndicator } from '../Streaks';

export default function AdvancedTable({ table }) {
  const history = useHistory();
  const { user, updateUser, isPrivacyActive, disablePrivacy } = useAuth();
  const { weeks: allWeeks, reviewData } = useTable();

  const settings = user ? JSON.parse(user.tracker_settings) : false;

  const { data, updateData } = useData();
  const { habits, reviews, date: dateInfo } = data;

  const [weeks, setWeeks] = useState([]);
  const [editingHabit, setEditingHabit] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [reviewInputs, setReviewInputs] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const length = table === 'review' ? 4 : Math.floor(window.innerWidth / 100);

  useEffect(() => {
    const allWeeksCopy = [...allWeeks];
    const currentWeekyear = weekYearToNumber(getWeekYear());
    const aWeekAgo = weekYearToNumber(getWeekYear({ numOfWeeks: -1 }));
    const twoWeeksAgo = weekYearToNumber(getWeekYear({ numOfWeeks: -2 }));

    let lastPassedWeek = 0;
    if (dateInfo !== null) {
      if (dateInfo?.lastReview === currentWeekyear) {
        lastPassedWeek = currentWeekyear;
      } else if (dateInfo?.lastReview === aWeekAgo) {
        lastPassedWeek = aWeekAgo;
      } else {
        lastPassedWeek = twoWeeksAgo;
      }

      const lastReviewIndex = allWeeksCopy.findIndex(w => weekYearToNumber(w.weekYear) === lastPassedWeek);

      const lastWeeks = allWeeksCopy.slice(lastReviewIndex - length + 1, lastReviewIndex + 1);

      if (!lastWeeks.length) {
        // Get last `n = length` elements in ASC order.
        return setWeeks(allWeeksCopy.slice(allWeeksCopy.length - length, allWeeksCopy.length));
      }
      return setWeeks(lastWeeks);
    }
  }, [table, allWeeks, dateInfo]);

  useEffect(() => {
    const baseReviewInputs = () => {
      const calculateScore = (logs, habitMax) => {
        const divided = logs / habitMax;
        if (divided < 0.125) return 0;
        if (divided < 0.375) return 1;
        if (divided < 0.625) return 2;
        if (divided < 0.875) return 3;
        return 4;
      };

      let weekLogs;

      if (reviewData.missedLastReview) {
        weekLogs = getLogsOfTheWeek(data.logs, user.tracker_settings, 1);
      } else {
        weekLogs = getLogsOfTheWeek(data.logs, user.tracker_settings);
      }

      const inputs = data.habits
        .filter(h => {
          if (!h.active) {
            return false;
          }

          const habitReviews = reviews.filter(review => review.habit_id === h.id);
          const onlyDatesInOrder = [
            ...new Set(habitReviews.map(review => weekYearToNumber(review.weekyear)).sort((a, b) => a - b)),
          ];
          const lastReview = onlyDatesInOrder[onlyDatesInOrder.length - 1];

          let weekyear;

          if (reviewData.missedLastReview) {
            weekyear = weekYearToNumber(getWeekYear({ numOfWeeks: -1 }));
          } else {
            weekyear = weekYearToNumber(getWeekYear());
          }

          if (lastReview === weekyear) {
            return false;
          } else {
            return h;
          }
        })
        .map((habit, index) => {
          const habitLogs = weekLogs.filter(l => l.habit_id === habit.id);
          const sumOfLogs = habitLogs.map(l => l.value).reduce((a, b) => a + b, 0);
          const habitMaxTimes = maxTimesHabit(habit);

          return {
            index,
            habit_id: habit.id,
            score: false,
            sumOfLogs,
            habitMaxTimes,
            suggestedScore: calculateScore(sumOfLogs, habitMaxTimes),
            notes: '',
            insights: '',
          };
        });
      setReviewInputs(inputs);
    };

    !!weeks.length && table === 'review' && baseReviewInputs();
  }, [weeks, table]);

  const verifyInputs = inputs => {
    const isAnyEmpty = inputs && inputs.find(i => i.score === false);
    if (isAnyEmpty || submitLoading) {
      return 'disabled';
    } else {
      return '';
    }
  };

  const flagReflected = weekyear => {
    return new Promise((resolve, reject) => {
      api
        .patch('/user', {
          tracker_settings: JSON.stringify({ ...settings, lastFilledReflection: weekyear }),
        })
        .then(() => resolve())
        .catch(err => reject(err));
    });
  };

  const handleSubmitReview = async () => {
    setSubmitLoading(true);

    let weekyear;

    if (reviewData.missedLastReview) {
      weekyear = getWeekYear({ numOfWeeks: -1 });
    } else {
      weekyear = getWeekYear();
    }

    if (dateInfo.lastReview === weekYearToNumber(weekyear)) {
      toast.error(`You already submited this week's review`, { position: 'top-center' });
      return;
    }

    const parsedInputs = reviewInputs.map(r => {
      return {
        insights: r.insights ? r.insights : null,
        notes: r.notes ? r.notes : null,
        score: r.score,
        habit_id: r.habit_id,
        weekyear,
      };
    });

    try {
      const { data: newReviews } = await api.post('/reviews', { data: parsedInputs });
      window.analytics.track('Finished Reflection');
      toast.success(
        <p className="mb-0">
          Congratulations 🎉 <br /> Your review was submitted!
        </p>
      );
      toast.info("You're being redirected to see your progress");

      flagReflected(weekYearToNumber(weekyear));
      setTimeout(() => {
        history.push('/myday');
        updateData('addReviews', newReviews);
        updateUser(
          'tracker_settings',
          JSON.stringify({ ...settings, lastFilledReflection: weekYearToNumber(weekyear) })
        );
      }, 2000);
    } catch (err) {
      setSubmitLoading(false);
      toast.error('Error!', { position: 'top-center' });
    }
  };

  return (
    <S.Container privacyMode={isPrivacyActive}>
      {isPrivacyActive && <S.DisablePrivacyMode onClick={disablePrivacy}>Disable Privacy Mode</S.DisablePrivacyMode>}

      <S.SubtitlesContainer table={table} advWeeks={weeks && weeks.length}>
        <S.HabitsSubtitle table={table}>My habits</S.HabitsSubtitle>
        <h5 id="weeks-subtitle" size={weeks && weeks.length * 5}>
          Your {habits.length > 0 && weeks ? weeks.length : 0}-week progress
        </h5>
        {table === 'review' && (
          <h5 id="review-subtitle">
            {`Reflection for ${getReviewDaysRange(reviewData.missedLastReview, user.tracker_settings)}`}
          </h5>
        )}
      </S.SubtitlesContainer>

      <S.Header className="text-600" table={table}>
        <div className="weeks">
          {habits.length > 0 && weeks && weeks.map((el, i) => <span key={i}>{el.date}</span>)}
        </div>

        {table === 'review' && reviewInputs && <div className="reviews" />}
      </S.Header>

      {weeks &&
        habits
          .filter(h => {
            if (table === 'review') {
              if (!h.active) {
                return false;
              }

              const habitReviews = reviews.filter(review => review.habit_id === h.id);
              const onlyDatesInOrder = [
                ...new Set(habitReviews.map(review => weekYearToNumber(review.weekyear)).sort((a, b) => a - b)),
              ];
              const lastReview = onlyDatesInOrder[onlyDatesInOrder.length - 1];

              let weekyear;

              if (reviewData.missedLastReview) {
                weekyear = weekYearToNumber(getWeekYear({ numOfWeeks: -1 }));
              } else {
                weekyear = weekYearToNumber(getWeekYear());
              }

              if (lastReview === weekyear) {
                return false;
              } else {
                return h;
              }
            } else {
              return h;
            }
          })
          .sort((a, b) => b.active - a.active)
          .map(habit => {
            const habitReviews = reviews.filter(review => review.habit_id === habit.id);

            const scoresOfHabit = prepareScores(weeks, habitReviews);

            return (
              <S.Row key={habit.id} style={{ filter: !habit.active && 'opacity(0.5)' }} table={table}>
                <HabitName table={table} habit={habit} editingHabit={editingHabit} setEditingHabit={setEditingHabit} />

                <div className="scores">
                  <StreakIndicator scores={scoresOfHabit} />
                  {weeks.map((week, weekIndex) => {
                    const weekReview = habitReviews.find(review => review.weekyear === week.weekYear);
                    const isWeekAtLeastOneWeekAgo = moment(moment(week.momentObj).add(1, 'week')).isBefore(moment());

                    let pastWeekColor;

                    if (!weekReview?.score) {
                      for (let i = weekIndex; i >= 0; i--) {
                        let pastWeekReview = habitReviews.find(review => review.weekyear === weeks[i].weekYear);
                        if (pastWeekReview?.score != null) {
                          pastWeekColor = colorParser(pastWeekReview.score);
                          break;
                        }
                      }
                    }

                    if (weekReview || isWeekAtLeastOneWeekAgo) {
                      return (
                        <HabitScore
                          table={table}
                          habit={habit}
                          key={`${habit.id}-${week.week}`}
                          review={weekReview}
                          noReview={!weekReview}
                          noReviewData={{
                            habit_id: habit.id,
                            weekyear: week.weekYear,
                          }}
                          deactivate={!habit.active}
                          background={weekReview?.score != null ? colorParser(weekReview.score) : null}
                          pastWeekColor={pastWeekColor || '#e8e8e8'}
                        />
                      );
                    } else if (!habit.active) {
                      return (
                        <HabitScore
                          table={table}
                          habit={habit}
                          key={`${habit.id}-${week.week}`}
                          background={'#d9d9d9'}
                          deactivate={true}
                        />
                      );
                    } else {
                      return (
                        <HabitScore
                          table={table}
                          habit={habit}
                          key={`${habit.id}-${week.week}`}
                          background={'	'}
                          deactivate={true}
                        />
                      );
                    }
                  })}
                </div>

                {table === 'review' && reviewInputs && (
                  <HabitReview
                    habit={habit}
                    reviewInput={reviewInputs.find(input => input.habit_id === habit.id)}
                    reviewInputs={reviewInputs}
                    setReviewInputs={setReviewInputs}
                  />
                )}
              </S.Row>
            );
          })}
      {table === 'review' ? (
        <S.Footer table={table}>
          {weeks &&
            !editingHabit &&
            !isActive &&
            weeks.map((el, i) => <ProgressScore table={table} week={el} key={i} />)}

          <ProgressScore
            table={table}
            week={{
              score: calculateScore(habits, [], reviewInputs),
              improvement: calculateImprovement(
                calculateScore(habits, [], reviewInputs),
                calculateScore(
                  habits,
                  (function() {
                    const weeksWithScores = weeks.filter(w => w.hasReviews);
                    const filteredReviews = reviews.filter(
                      r => r.weekyear === weeksWithScores[weeksWithScores.length - 1]?.weekYear
                    );
                    return filteredReviews;
                  })()
                )
              ),
              improvementColor: improvementColor(
                calculateImprovement(
                  calculateScore(habits, [], reviewInputs),
                  calculateScore(
                    habits,
                    (function() {
                      const weeksWithScores = weeks.filter(w => w.hasReviews);
                      const filteredReviews = reviews.filter(
                        r => r.weekyear === weeksWithScores[weeksWithScores.length - 1]?.weekYear
                      );
                      return filteredReviews;
                    })()
                  )
                )
              ),
              hasReviews: true,
            }}
            isForReviewTable
          />
          <S.ReviewsFooter>
            <div
              className={verifyInputs(reviewInputs)}
              onClick={!verifyInputs(reviewInputs) ? handleSubmitReview : undefined}
            >
              Submit Reflection
            </div>
          </S.ReviewsFooter>
        </S.Footer>
      ) : (
        <S.Footer>
          <AddHabitRow
            editingHabit={editingHabit}
            setEditingHabit={setEditingHabit}
            isActive={isActive}
            setIsActive={setIsActive}
          />
          {habits.length > 0 &&
            weeks &&
            !editingHabit &&
            !isActive &&
            weeks.map((el, i) => <ProgressScore table={table} week={el} key={i} />)}
        </S.Footer>
      )}
    </S.Container>
  );
}
