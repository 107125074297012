import React from 'react';
import { Chat, LoadingIndicator, useChatContext } from 'stream-chat-react';
import './MessageView.css';
import ChatContent from '../../components/messaging/ChatContent';

export default function Messages() {
  const { client } = useChatContext();

  const customClasses = {
    channelList: 'str-chat-channel-list',
  };

  if (!client) return <LoadingIndicator size={250} />;

  return (
    <Chat customClasses={customClasses} client={client}>
      <ChatContent />
    </Chat>
  );
}
