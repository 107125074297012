import React, { useState } from 'react';
import Emoji from './Emoji';
import { calculateHint, maxTimesHabit, textParser } from '../../../helpers/tracker-parsers/parsers';

import * as S from './styled';

const ScoreNumber = ({ habit, currentScore, changeScore, numbers, ...props }) => {
  const [hoverScore, setHoverScore] = useState(false);

  const numberToEmoji = num => {
    if (num === 0) return { symbol: '💩', label: '1' };
    if (num === 1) return { symbol: '😕', label: '2', extraLeft: true };
    if (num === 2) return { symbol: '🙂', label: '3', extraLeft: true };
    if (num === 3) return { symbol: '😄', label: '4', extraLeft: true };
    if (num === 4) return { symbol: '🎉', label: '5' };
  };

  const handleClick = num => {
    if (num === currentScore) {
      changeScore(false);
    } else {
      changeScore(num);
    }
  };

  return (
    <S.ScoreContainer tabIndex="0" {...props}>
      <div className="numbers">
        {[...Array(5)].map((x, i) => (
          <S.ScoreBall
            key={i}
            id={`scoreball-index-${i}`}
            currentScore={currentScore !== null && hoverScore === false && currentScore >= i && currentScore}
            className="shadow-sm"
            onClick={() => handleClick(i)}
            hoverScore={hoverScore !== null && hoverScore >= i && hoverScore}
            onMouseEnter={() => setHoverScore(i)}
            onMouseLeave={() => setHoverScore(false)}
            onFocus={() => setHoverScore(i)}
            onBlur={() => setHoverScore(false)}
            onKeyDown={e => {
              e.stopPropagation();
              if (e.keyCode === 32) {
                handleClick(i);
              }
            }}
            tabIndex="0"
          >
            <Emoji emoji={numberToEmoji(i)} />
          </S.ScoreBall>
        ))}
        {!props.newHabit && (
          <S.ScoreBall
            id={`scoreball-index-skip`}
            currentScore={hoverScore === false && currentScore === null && currentScore}
            className="shadow-sm"
            onClick={() => handleClick(null)}
            hoverScore={hoverScore === null && hoverScore}
            onMouseEnter={() => setHoverScore(null)}
            onMouseLeave={() => setHoverScore(false)}
            onFocus={() => setHoverScore(null)}
            onBlur={() => setHoverScore(false)}
            onKeyDown={e => {
              e.stopPropagation();
              if (e.keyCode === 32) {
                handleClick(null);
              }
            }}
            tabIndex="0"
          >
            <span className="fs-1 text-danger font-weight-bold">/</span>
          </S.ScoreBall>
        )}
      </div>
      <S.ScoreLabel className="label" hoverScore={hoverScore} currentScore={!hoverScore && currentScore} {...props}>
        {hoverScore === false ? textParser(currentScore) : textParser(hoverScore)}
        {currentScore === false && hoverScore === false && <span className="not-selected">Not selected yet</span>}
        <span className="hint">
          {calculateHint(hoverScore !== false ? hoverScore : currentScore, maxTimesHabit(habit))}
        </span>
      </S.ScoreLabel>
    </S.ScoreContainer>
  );
};

export default ScoreNumber;
