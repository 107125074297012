import styled from 'styled-components';
import media from 'styled-media-query';
import { Button, FormGroup, FormText } from 'reactstrap';

export const Container = styled.div`
  width: 100%;
  min-width: 14rem;

  margin-left: 0.5rem;
`;

export const NewHabitButton = styled.div`
  width: 100%;
  min-height: 3.5rem;
  border-radius: 25px;
  padding-left: 1.5rem;

  display: flex;
  align-items: center;

  font-size: 0.9rem;
  font-weight: bold;
  color: rgba(55, 53, 47, 0.4);
  background: #e8e8e8;

  &:hover {
    cursor: pointer;
    background: #dedede;
  }
`;

export const NewHabitForm = styled.div`
  width: 100%;
  border-radius: 25px;
  padding: 0.5rem 1.5rem;

  background: #fff;

  display: grid;
  grid-template-columns: ${props => (props.editingHabit ? 'repeat(5, 1fr)' : 'repeat(6, 1fr)')};
  gap: 1rem;
  justify-items: flex-start;
  align-items: center;

  position: relative;

  & > div {
    max-width: 100%;
  }

  ${media.lessThan('huge')`
  grid-template-columns: ${props => (props.editingHabit ? 'repeat(3, 1fr)' : 'repeat(3, 1fr)')};
  `}

  ${media.lessThan('medium')`
  grid-template-columns: repeat(1, 1fr);
  `}
`;

export const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 20px;

  cursor: pointer;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;

  &:hover {
    filter: opacity(0.8);
  }
`;

export const InputContainer = styled(FormGroup)`
  margin: 0;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: self-start;

  label {
    font-size: 0.6rem;
    margin: 0;
  }
`;

export const HelperText = styled(FormText)`
  font-size: 0.6rem;
  margin-top: 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;

  ${media.between('medium', 'huge')`
  grid-column: ${props => props.editing && '2 / 4'};
  `}
`;

export const SubmitButton = styled(Button)`
  height: 3rem;
  width: ${props => props.w100 && '100%'};
`;
