import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Channel, Window, Thread, MessageList, MessageInput, ChannelList, useChatContext } from 'stream-chat-react';
import { useAuth } from '../../../context/AuthContext';
import CustomChannelHeader from '../CustomChannelHeader';
import { EmptyChannelContent } from '../EmptyChannelContent';

export default function ChatContent() {
  const params = useParams();

  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(params.userId ? true : false);

  const sort = { last_message_at: -1 };

  const { user } = useAuth();

  const filters = {
    members: { $in: [user.id.toString()] },
    type: 'messaging',
  };

  const { channel, setActiveChannel, client } = useChatContext();

  const findRedirectedChannel = (channels, partnerId) => {
    for (const channel of channels) {
      const members = channel.state.members;
      if (Object.keys(members).includes(partnerId.toString())) {
        return channel;
      }
    }
    return null;
  };

  const setRedirectedChannel = async () => {
    try {
      const channels = await client.queryChannels(filters);
      const redirectedChannel = findRedirectedChannel(channels, params.userId);
      if (!redirectedChannel) {
        setLoading(false);
        return;
      }
      setActiveChannel(redirectedChannel, { limit: 1, offset: 1 });
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
      console.log(e);
    }
  };

  useLayoutEffect(() => {
    if (params.userId) {
      setRedirectedChannel();
    }
  }, []);

  const partnerUserId =
    Object.keys(channel?.state ? channel.state.members : {}).find(id => id != user.id.toString()) || '';

  const [visible, setVisible] = useState(!channel ? true : false);

  const partnerUserInfo = channel?.state && channel?.state.members[partnerUserId];

  if (!client || loading) return null;

  const renderEmptyChannelContent = !channel && error;

  return (
    <>
      <div className={visible ? 'channel-list-wrapper--active' : 'channel-list-wrapper'}>
        <ChannelList filters={filters} sort={sort} />
      </div>
      {renderEmptyChannelContent && <EmptyChannelContent visible={visible} setVisible={setVisible} />}
      <Channel channel={channel}>
        {channel && (
          <Window>
            <CustomChannelHeader
              visible={visible}
              setVisible={setVisible}
              title={partnerUserInfo && partnerUserInfo.user.name}
              isOnline={partnerUserInfo && partnerUserInfo.user.online}
            />
            <MessageList />
            <MessageInput />
          </Window>
        )}
        <Thread />
      </Channel>
    </>
  );
}
