import React, { useState } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../common/Flex';
import { isIterableArray } from '../../helpers/utils';
import api from '../../services/api';

const PricingRowAlt = ({ isDisable, isLast, children }) => (
  <li className={classNames('py-2', { 'border-bottom': !isLast, 'text-300': isDisable })}>
    <FontAwesomeIcon icon="check" transform="shrink-2" className={classNames({ 'text-primary': !isDisable })} />{' '}
    {children}
  </li>
);

export default function Plan({ type, price, icon, features, button, isYearly, priceId, alreadyHasPlan }) {
  const plan = isYearly ? 'year' : 'month';
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async priceId => {
    setIsLoading(true);
    try {
      const { data } = await api.post('/create-checkout', { priceId });
      window.top.location.href = data.url;
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <div className="border rounded-soft overflow-hidden mb-3 mb-md-0">
      <Flex align="center" justify="between" className="p-4 bg-light">
        <div>
          <h3 className="font-weight-light fs-5 mb-0 text-primary">{type}</h3>
          <h2 className="font-weight-light mt-0 text-primary">
            <sup className="fs-1">$</sup>
            <span className="fs-3">{price[plan]}</span>
            <span className="fs--2 mt-1">/ {plan}</span>
          </h2>
        </div>
        <div className="pr-3">
          <FontAwesomeIcon icon={icon} />
        </div>
      </Flex>
      <div className="p-4 bg-light">
        <ul className="list-unstyled">
          {isIterableArray(features) &&
            features.map((feature, index) => (
              <PricingRowAlt isDisable={feature.isDisable} key={index} isLast={features.length === index + 1}>
                {feature.title}
              </PricingRowAlt>
            ))}
        </ul>
        <Button
          color={button.color}
          block
          disabled={alreadyHasPlan || isLoading || !priceId}
          onClick={() => handleClick(priceId)}
        >
          {button.text}
        </Button>
      </div>
    </div>
  );
}
