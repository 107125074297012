import React from 'react';
import * as S from './styled';
import { CustomInput } from 'reactstrap';

export const NotificationCheckbox = ({ onChange, checked, id, label, disabled, explanationText }) => {
  return (
    <S.NotificationCheckboxContainer>
      <CustomInput
        id={id}
        label={label}
        checked={checked}
        onChange={() => onChange()}
        type="checkbox"
        disabled={disabled}
      />
      <S.NotificationCheckboxLabel>{explanationText}</S.NotificationCheckboxLabel>
    </S.NotificationCheckboxContainer>
  );
};
