import firebase from 'firebase';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBwP3yP3dmsyMdDEFfiAHiIDtfdNzxtnJQ',
  authDomain: 'weekup-11b6f.firebaseapp.com',
  projectId: 'weekup-11b6f',
  storageBucket: 'weekup-11b6f.appspot.com',
  messagingSenderId: '1000711503919',
  appId: '1:1000711503919:web:6dad8c531b9d23b55946da',
  measurementId: 'G-PVKNXWJF7J',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
