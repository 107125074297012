import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  width: 100vw;
  height: 4rem;
  margin-left: -15px;
  padding-left: 1.5rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: white;
  background-color: #e06666;

  ${media.lessThan('medium')`
    display: none; 
  `}

  button {
    color: #fff;
    border: 1px solid #fff;
    cursor: pointer;

    padding: 6px 8px;
    margin: 0 1rem;

    border-radius: 0px;

    &:hover {
      color: #fff;
      text-decoration: none;
      opacity: 0.8;
    }
  }
`;
