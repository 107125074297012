import moment from 'moment';
import React, { useContext, useEffect, createContext } from 'react';
import { toast } from 'react-toastify';
import { momentToWeekYear, parsePeriodStartHour, parseHabitPeriod } from '../helpers/tracker-parsers/parsers';
import api from '../services/api';
import { useData } from './DataContext';

const LogsContext = createContext();

export const useLogs = () => {
  const context = useContext(LogsContext);

  return context;
};

export const LogsProvider = ({ children }) => {
  const { data, updateData } = useData();
  const { habits, logs, date: dateInfo } = data;

  const hourParser = el => {
    const frequency = JSON.parse(el.frequency);
    if (frequency.time.value > 1) {
      const momentObject = moment('23:59', [moment.ISO_8601, 'HH:mm']);
      return Number(`${momentObject.unix()}0`);
    }
    if (frequency.time.period === 'unspecified') {
      const momentObject = moment('23:59', [moment.ISO_8601, 'HH:mm']);
      return Number(`${momentObject.unix()}00`);
    }
    if (frequency.time.period !== 'range' && frequency.time.period !== 'exact') {
      const hour = parsePeriodStartHour(frequency.time.period);
      const momentObject = moment(hour, [moment.ISO_8601, 'HH:mm']);
      return momentObject.unix();
    }
    const hour = frequency.time.start;
    const momentObject = moment(hour, [moment.ISO_8601, 'HH:mm']);
    return momentObject.unix();
  };

  const todayHabits = habits
    .filter(h => {
      const today = dateInfo.dayOfTheWeek;
      const frequency = JSON.parse(h.frequency);

      if (h.active && (frequency.repeat.type === 'daily' && frequency.repeat.days.includes(today))) {
        return h;
      }
      if (h.active && (frequency.repeat.type === 'weekly' || frequency.repeat.type === 'everyday')) {
        return h; //always returning if weekly or everyday because log logic is off

        // const isThereALogToday = logs
        //   .filter(l => {
        //     const logDate = moment(l.date, 'YYYY-MM-DD').format('DD/MM');
        //     if (logDate === moment().format('DD/MM')) {
        //       return l;
        //     }
        //   })
        //   .find(l => l.habit_id === h.id);

        // if (isThereALogToday) {
        //   return h;
        // }

        // const numberOfLogs = logs.filter(l => {
        //   const logWeekYear = momentToWeekYear(moment(l.date, 'YYYY-MM-DD'));
        //   const isLogFromThisHabit = l.habit_id === h.id;
        //   if (logWeekYear === dateInfo.currentWeekyear && isLogFromThisHabit) {
        //     return l;
        //   }
        // }).length;

        // if (frequency.repeat.type === 'weekly' && numberOfLogs < Number(frequency.repeat.times)) {
        //   return h;
        // }

        // if (frequency.repeat.type === 'everyday' && numberOfLogs < 7) {
        //   return h;
        // }
      }
    })
    .map(h => {
      const frequency = JSON.parse(h.frequency);
      const todayLogs = logs.filter(l => {
        const logDate = moment(l.date, 'YYYY-MM-DD').format('DD/MM');
        if (logDate === moment().format('DD/MM')) {
          return l;
        }
      });

      const habitLog = todayLogs.find(l => l.habit_id === h.id);

      const logValue = habitLog ? habitLog.value : false;

      // setting maxValue as 1 always because we're not using the full logic anymore
      // so habits that happens multiple times a day have the same behavior as if it's a single time habit
      // const maxValue = frequency.time.value;
      const maxValue = 1;

      const finished = logValue === maxValue;

      const period = parseHabitPeriod(frequency);

      return { ...h, period, habitLog, logValue, maxValue, finished };
    })
    .sort((a, b) => hourParser(a) - hourParser(b));

  const otherHabits = habits
    .map(h => {
      const today = dateInfo.dayOfTheWeek;
      const frequency = JSON.parse(h.frequency);
      if (!h.active) {
        return h;
      }
      if (frequency.repeat.type === 'daily' && frequency.repeat.days.includes(today)) {
        return false;
      }
      if (frequency.repeat.type === 'weekly' || frequency.repeat.type === 'everyday') {
        return false; //weekly or everyday habits are not other because they are daily (log logic is off)
        // const isThereALogToday = logs

        //   .filter(l => {
        //     const logDate = moment(l.date, 'YYYY-MM-DD').format('DD/MM');
        //     if (logDate === moment().format('DD/MM')) {
        //       return l;
        //     }
        //   })
        //   .find(l => l.habit_id === h.id);

        // if (isThereALogToday) {
        //   return false;
        // }

        // const numberOfLogs = logs.filter(l => {
        //   const logWeekYear = momentToWeekYear(moment(l.date, 'YYYY-MM-DD'));
        //   const isLogFromThisHabit = l.habit_id === h.id;
        //   if (logWeekYear === dateInfo.currentWeekyear && isLogFromThisHabit) {
        //     return l;
        //   }
        // }).length;

        // if (frequency.repeat.type === 'weekly' && numberOfLogs < Number(frequency.repeat.times)) {
        //   return false;
        // }

        // if (frequency.repeat.type === 'everyday' && numberOfLogs < 7) {
        //   return false;
        // }
      }
      return h;
    })
    .filter(h => h !== false);

  useEffect(() => {});

  const handleLogClick = async habit => {
    const { habitLog, logValue, maxValue } = habit;

    if (logValue === false) {
      const newLog = { habit_id: habit.id, date: moment().format(), value: 1 };
      try {
        const newLogRes = await api.post('/log', newLog);
        updateData('addLog', newLogRes.data);
      } catch (err) {
        toast.error('Error', { position: 'top-center' });
      }
    } else if (logValue === maxValue) {
      const resetLog = { id: habitLog.id, value: 0 };
      try {
        const resetLogRes = await api.patch('/log', resetLog);
        updateData('updateLog', resetLogRes.data);
      } catch (err) {
        toast.error('Error', { position: 'top-center' });
      }
    } else {
      const updatedLog = { id: habitLog.id, value: logValue + 1 };
      try {
        const updatedLogRes = await api.patch('/log', updatedLog);
        updateData('updateLog', updatedLogRes.data);
      } catch (err) {
        toast.error('Error', { position: 'top-center' });
      }
    }
  };

  return <LogsContext.Provider value={{ todayHabits, otherHabits, handleLogClick }}>{children}</LogsContext.Provider>;
};
