import React from 'react';
import moment from 'moment';

import { frequencyToHour, parseFrequency } from '../../../helpers/tracker-parsers/parsers';

import * as S from './styled';

export default function HabitFrequency({ frequency, prefix, periodColor, today, friendSettings }) {
  const generateFriendsPrefix = (frequency, friendSettings, key) => {
    const time = frequencyToHour(frequency, true);

    let prefix;

    if (time === 'Anytime today' || time === 'Several times today') {
      prefix = { name: '', color: 'grey' };
      return prefix[key];
    }

    const now = moment(
      moment
        .utc()
        .subtract(friendSettings.timezoneOffset, 'minutes')
        .format('HH:mm'),
      [moment.ISO_8601, 'HH:mm']
    );
    const start = moment(time.start, [moment.ISO_8601, 'HH:mm']);
    const end = moment(time.end, [moment.ISO_8601, 'HH:mm']);

    if (now.isBetween(start, end, null, '[]')) {
      prefix = { color: 'red', name: 'Due now' };
      return prefix[key];
    }

    if (start.isBetween(now, moment(now, [moment.ISO_8601, 'HH:mm']).add(3, 'hours'), null, '[]')) {
      prefix = { color: 'orange', name: 'Get ready' };
      return prefix[key];
    }

    if (end.isBefore(now)) {
      prefix = { color: 'grey', name: 'Past due' };
      return prefix[key];
    }

    prefix = { color: 'grey', name: 'Later' };
    return prefix[key];
  };

  return (
    <S.Wrapper className="updateLog">
      {!friendSettings && today && (
        <S.Hour className={`${!periodColor && 'text-800'} updateLog`} periodColor={periodColor}>
          {prefix ? `${prefix} ` : ''}
          {frequencyToHour(frequency)}
        </S.Hour>
      )}

      {friendSettings && friendSettings.timezoneOffset && today && (
        <S.Hour periodColor={generateFriendsPrefix(frequency, friendSettings, 'color')} partner>
          {generateFriendsPrefix(frequency, friendSettings, 'name')}
          {frequencyToHour(frequency)} <span>(their time)</span>
        </S.Hour>
      )}

      <div className="fs--1 font-weight-bold updateLog" style={{ color: '#9fa0a2' }}>
        {parseFrequency(frequency)}
      </div>
    </S.Wrapper>
  );
}
