import React from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import * as S from './styled';

export const SubscriptionModal = ({ isOpen, closeModal }) => {
  const location = useLocation();

  const goToSubscribePage = () => {
    window.top.location.href = '/plans';
  };

  return (
    <Modal centered size="lg" isOpen={isOpen}>
      <S.Header>You have used your 3 free habits.</S.Header>
      <ModalBody>To add additional habits, upgrade to Weekup Premium and track unlimited habits!</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => goToSubscribePage()}>
          Upgrade
        </Button>
        <Button onClick={() => closeModal()}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};
