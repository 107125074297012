import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useData } from '../../../context/DataContext';
import api from '../../../services/api';
import ReviewInsights from '../ReviewInsights';

import ScoreNumber from '../ScoreNumber';
import ReflectModal from '../ReflectModal';
import * as S from './styled';
import ButtonIcon from '../../common/ButtonIcon';

const HabitScore = ({
  table,
  habit,
  review,
  noReview,
  noReviewData,
  deactivate,
  background,
  pastWeekColor,
  friend,
  goToReview,
}) => {
  const history = useHistory();
  const { updateData } = useData();
  const node = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [currentScore, setCurrentScore] = useState(review ? review.score : false);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isReflectModalOpen, setIsReflectModalOpen] = useState(false);
  const [reviewInput, setReviewInput] = useState({
    notes: review?.notes || '',
    insights: review?.insights || '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setReviewInput({ ...reviewInput, [name]: value });
  };

  const handleSave = async () => {
    const { notes, insights } = reviewInput;
    try {
      await api.patch('/review', { id: review.id, notes, insights });
      updateData('updateReflection', { notes, insights }, review);
      toast.success('Reflection updated with success.');
    } catch (err) {
      window.alert(err);
      toast.error('There was an error updating this review.', { position: 'top-center' });
    }
  };

  const handleClickOutside = e => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }

    setIsActive(false);
  };

  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActive]);

  const handleScoreChange = async newScore => {
    if (noReview) {
      try {
        const { data: newReview } = await api.post('/review', {
          score: newScore,
          habit_id: noReviewData.habit_id,
          weekyear: noReviewData.weekyear,
        });
        window.analytics.track('Updated score');
        updateData('addReviews', [newReview]);
        setCurrentScore(newScore);
        toast.success('Review updated with success.');
        setIsActive(false);
      } catch (err) {
        window.alert(err);
        toast.error('There was an error updating this review.', { position: 'top-center' });
      }
    } else {
      try {
        await api.patch('/review', { id: review.id, score: newScore });
        window.analytics.track('Updated score');
        updateData('updateScore', newScore, review);
        setCurrentScore(newScore);
        toast.success('Review updated with success.');
        setIsActive(false);
      } catch (err) {
        window.alert(err);
        toast.error('There was an error updating this review.', { position: 'top-center' });
      }
    }
  };

  const handleClick = () => {
    if (goToReview) {
      window.analytics.track('Started Reflection');
      history.push({
        pathname: '/allhabits',
        state: { reviewTime: true },
      });
    } else {
      setIsActive(true);
    }
  };

  return (
    <S.Container
      id={`score-${review && review.id}`}
      style={{
        background:
          background ||
          `linear-gradient(135deg, ${pastWeekColor}90 4.17%, ${pastWeekColor} 4.17%, ${pastWeekColor} 50%, ${pastWeekColor}90 50%, ${pastWeekColor}90 54.17%, ${pastWeekColor} 54.17%, ${pastWeekColor} 100%)`,
        backgroundSize: background ? null : '15.97px 15.97px',
      }}
      onClick={!deactivate && !friend ? handleClick : undefined}
      onMouseEnter={() => review && (review.notes || review.insights) && setIsOpen(true)}
      onMouseLeave={() => review && (review.notes || review.insights) && setIsOpen(false)}
      className={`score ${deactivate || isActive ? 'disabled' : 'score-active'}`}
      table={table ? table : 'standard'}
      friend={friend}
    >
      {review && (review.notes || review.insights) && (
        <>
          <S.TopLeftTriangle />

          <ReviewInsights
            target={`score-${review && review.id}`}
            notes={review && review.notes}
            insights={review && review.insights}
            isOpen={isOpen}
            toggleOpen={() => setIsOpen(!isOpen)}
          />
        </>
      )}
      {isActive && (
        <S.DropDown ref={node} withReflect={!noReview}>
          <ScoreNumber habit={habit} currentScore={currentScore} changeScore={handleScoreChange} />
          {!noReview && (
            <ButtonIcon size="sm" icon="plus" onClick={() => setIsReflectModalOpen(true)} className="fs--2 btn-reflect">
              Note
            </ButtonIcon>
          )}
        </S.DropDown>
      )}
      {goToReview && (
        <>
          <Tooltip
            placement="left"
            target={`review-${habit.id}`}
            isOpen={isTooltipOpen}
            toggle={() => setIsTooltipOpen(!isTooltipOpen)}
          >
            Click to reflect on the week
          </Tooltip>
          <div id={`review-${habit.id}`} className="d-flex flex-column justify-content-center align-items-center h-100">
            <FontAwesomeIcon icon="exclamation-triangle" />
            <span className="fs--2 mt-1 font-weight-bold">REVIEW</span>
          </div>
        </>
      )}
      {!noReview && isReflectModalOpen && (
        <ReflectModal
          habit={habit}
          reviewInput={reviewInput}
          handleChange={handleChange}
          handleSave={handleSave}
          isOpen={isReflectModalOpen}
          toggleOpen={() => setIsReflectModalOpen(!isReflectModalOpen)}
        />
      )}
    </S.Container>
  );
};

export default HabitScore;
