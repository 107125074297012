import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { colorParser, roundScore } from '../../../helpers/tracker-parsers/parsers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import * as S from './styled';

export default function HabitsPeriodWrapper({ period, friend, friendsScore, startHidden, isLast, children }) {
  const [hidden, setHidden] = useState(startHidden);

  const parsePeriodTitle = periodName => {
    if (periodName === 'other') return 'Other habits not due today';
    if (periodName === 'previous') return 'Previously today';
    if (periodName === 'now') return 'Coming up now';
    if (periodName === 'later') return 'Later today';
  };

  const handleClick = periodName => {
    window.analytics.track(`Clicked on "${parsePeriodTitle(periodName)}"`);
    setHidden(!hidden);
  };

  const startOfWeek = moment(friendsScore?.lastReview, '[W]WW[Y]GG').format('MMM D');

  const endOfWeek = moment(friendsScore?.lastReview, '[W]WW[Y]GG')
    .add(6, 'days')
    .format('MMM D');

  return (
    <S.Wrapper isLast={isLast}>
      <S.TitleWrapper onClick={() => handleClick(period)}>
        <S.Title>
          <FontAwesomeIcon icon={hidden ? 'caret-right' : 'caret-down'} className="mr-2" />{' '}
          {friend ? friend : parsePeriodTitle(period)}
        </S.Title>
        {friend && (
          <S.ScoreTextWrapper>
            <S.ScoreText color={colorParser(roundScore(friendsScore.score.toFixed(0)))}>
              <b>{friendsScore.score.toFixed(0)}%</b> overall score ({startOfWeek} - {endOfWeek})
            </S.ScoreText>
          </S.ScoreTextWrapper>
        )}
      </S.TitleWrapper>
      <Collapse isOpen={!hidden}>{children}</Collapse>
    </S.Wrapper>
  );
}
