import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useData } from '../../../context/DataContext';
import api from '../../../services/api';

const DeleteHabit = ({ habit, close, ...rest }) => {
  const { updateData } = useData();
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleDelete = async () => {
    try {
      await api.delete('/habit', { data: { id: habit.id } });
      close();
      updateData('deleteHabit', habit.id);
      toast.info('Habit deleted.');
      window.analytics.track('Deleted habit');
    } catch (err) {
      toast.error('Error.', { position: 'top-center' });
    }
  };

  return (
    <>
      <Button color="link" onClick={toggle} className="mx-2 text-danger" {...rest}>
        <u>Delete</u>
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Are you sure?</ModalHeader>
        <ModalBody>All related data will be deleted. This action is irreversible.</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleDelete}>
            Yes, I am sure. Delete the habit.
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DeleteHabit;
