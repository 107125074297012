import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChrome } from '@fortawesome/free-brands-svg-icons';
import { useData } from '../../context/DataContext';

import * as S from './styled';

function shouldShow(habits) {
  // if user is not on chrome doesn't show
  const isChrome = !!window.chrome;
  if (!isChrome) return false;

  // if user has the extension doesn't show
  let hasExtension = false;
  window.chrome.runtime &&
    window.chrome.runtime.sendMessage('cgcbkfhhcnajodpjddhpipljimehlnlp', { message: 'version' }, function(reply) {
      if (reply) {
        hasExtension = true;
      } else {
        hasExtension = false;
      }
    });
  if (hasExtension) return false;

  // if user already dismissed doesn't show
  const hideDownloadExtensionBanner = Cookies.get('hideDownloadExtensionBanner');
  if (hideDownloadExtensionBanner) return false;

  const habitsNotSample = habits.filter(h => !h.sample).length;

  if (habitsNotSample === 0) {
    return false;
  }

  // otherwise show
  return true;
}

export default function DownloadExtension() {
  const { data } = useData();
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    setVisible(shouldShow(data.habits));
  }, [data.habits]);

  const handleDismiss = () => {
    Cookies.set('hideDownloadExtensionBanner', true, { sameSite: 'none', secure: true, expires: 365 });
    setVisible(false);
  };

  if (visible) {
    return (
      <S.Wrapper>
        To track your due daily habits, get the extension from Google Chrome Webstore.{' '}
        <a
          href="https://chrome.google.com/webstore/detail/weekup/cgcbkfhhcnajodpjddhpipljimehlnlp?gclid=CjwKCAjwqIiFBhAHEiwANg9szuT_RMZQPqUDjDqigDuUz8ia2T0d78kMckv3wSfEwEJP7CzyW--9mhoCfPcQAvD_BwE"
          target="_blank"
          onClick={() => window.analytics.track(`Clicked on "Get the extension"`)}
        >
          <FontAwesomeIcon icon={faChrome} className="mr-1" />
          Get the extension
        </a>{' '}
        <button color="link" onClick={handleDismiss}>
          Dismiss
        </button>
      </S.Wrapper>
    );
  } else {
    return null;
  }
}
