import React, { useEffect, useState } from 'react';
import { scroller as scroll } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Label, Input } from 'reactstrap';

import { useAuth } from '../../../context/AuthContext';
import { useData } from '../../../context/DataContext';
import api from '../../../services/api';
import { getWeekYear } from '../../../helpers/tracker-parsers/helpers';
import { liabilityToEmoji } from '../../../helpers/tracker-parsers/parsers';

import ScoreNumber from '../ScoreNumber';
import FrequencyModal from '../FrequencyModal';
import DeleteHabit from './DeleteHabit';

import * as S from './styled';
import moment from 'moment';
import { SubscriptionModal } from '../SubscriptionModal';

const AddHabitRow = ({ editingHabit, setEditingHabit, isActive, setIsActive }) => {
  const { user, updateUser } = useAuth();
  const { data, updateData } = useData();

  const settings = user ? JSON.parse(user.tracker_settings) : false;

  const [habit, setHabit] = useState(null);
  const [reviews, setReviews] = useState([{ weekyear: null, score: null }]);
  const [isFrequencyOpen, setIsFrequencyOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(false);

  const firstDaysOfWeek = Number(moment().format('d')) === 1 || Number(moment().format('d')) === 2;

  const handleCancel = () => {
    setIsActive(false);
    setEditingHabit(false);
  };

  useEffect(() => {
    if (Boolean(editingHabit)) {
      scroll.scrollTo('addHabitRow', {
        duration: 500,
        delay: 0,
        smooth: true,
      });
    }
  }, [editingHabit]);

  useEffect(() => {
    if (!editingHabit) {
      const reviews = [{ weekyear: getWeekYear({ numOfWeeks: firstDaysOfWeek ? -1 : 0 }), score: 0 }];
      setReviews(reviews);
      setHabit({
        name: '',
        frequency: {
          duration: 30,
          repeat: { type: 'everyday', days: [], times: 1 },
          time: { value: 1, period: 'unspecified' },
        },
        motivation: '',
        category_id: '1',
        priority: 'medium',
      });
    }
  }, [isActive]);

  useEffect(() => {
    if (editingHabit) {
      const {
        index,
        friend_name,
        partner_id,
        partner_name,
        partner_email,
        period,
        partner_user_id,
        ...rest
      } = editingHabit;
      setHabit({ ...rest, frequency: JSON.parse(rest.frequency) });
      setIsActive(true);
    }
  }, [editingHabit]);

  const handleChange = e => {
    const { name, value } = e.target;
    setHabit({ ...habit, [name]: value });
  };

  const handleScoreChange = (value, week) => {
    const updatedReviews = [...reviews];

    updatedReviews[week] = { ...reviews[week], score: value };

    setReviews(updatedReviews);
  };

  const flagCreatedHabit = async () => {
    try {
      api.patch('/user', {
        tracker_settings: JSON.stringify({ ...settings, alreadyCreatedHabit: true }),
      });
      updateUser('tracker_settings', JSON.stringify({ ...settings, alreadyCreatedHabit: true }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async e => {
    if (!habit.name) {
      toast.info(`You must choose a name for your habit.`);
      return;
    }

    // VERIFY IF THERE ARE SOME MISSING SCORES

    let isEmpty = false;
    // eslint-disable-next-line
    reviews.map(el => {
      if (el.score === null) return (isEmpty = true);
    });
    if (isEmpty) {
      toast.info(`You must fill your last week's commitment`);
      return;
    }

    // PARSE HABIT AND SEND API REQUEST

    const parsedHabit = {
      ...habit,
      motivation: '',
      frequency: JSON.stringify(habit.frequency),
      category_id: parseInt(habit.category_id),
      active: true,
    };

    setLoading(true);

    try {
      const { data } = await api.post('/habit', { habit: parsedHabit, reviews });
      updateData('newHabit', data.habit, data.reviews);
      setLoading(false);
      setIsActive(false);
      toast.success('Success!');
      window.analytics.track('Created habit');
      if (!settings.alreadyCreatedHabit) {
        flagCreatedHabit();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setSubscriptionModalVisible(true);
      //toast.error('Free users cannot create more than three habits', { position: 'top-center' });
    }
  };

  const handleUpdate = async e => {
    const { finished, habitLog, logValue, maxValue, ...parsedHabit } = {
      ...habit,
      frequency: JSON.stringify(habit.frequency),
      category_id: parseInt(habit.category_id),
      sample: false,
    };

    setLoading(true);

    try {
      const { data: updatedHabit } = await api.patch('/habit', parsedHabit);
      updateData('updateHabit', { ...editingHabit, ...updatedHabit });
      setLoading(false);
      handleCancel();
      toast.success('Success!');
      window.analytics.track('Updated habit');
      if (!settings.alreadyCreatedHabit) {
        flagCreatedHabit();
      }
    } catch (err) {
      setLoading(false);
      toast.error('Error!', { position: 'top-center' });
    }
  };

  const toggleHabit = async () => {
    const toggledHabit = {
      id: habit.id,
      active: !habit.active,
    };

    try {
      const { data: updatedHabit } = await api.patch('/habit', toggledHabit);
      updateData('updateHabit', { ...editingHabit, ...updatedHabit });
      toast.success('Success!');
      handleCancel();
    } catch (err) {
      if (toggledHabit.active) {
        toast.error('You cannot activate more habits. Please upgrade to premium.', { position: 'top-center' });
      } else {
        toast.error('Error!', { position: 'top-center' });
      }
    }
  };

  return (
    <S.Container name="addHabitRow">
      {!isActive && (
        <S.NewHabitButton id="btn-open-new-habit" onClick={() => setIsActive(true)}>
          <FontAwesomeIcon icon="plus" size="lg" className="mr-2" /> New Habit
        </S.NewHabitButton>
      )}

      {isActive && (
        <S.NewHabitForm
          editingHabit={!!editingHabit}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              editingHabit ? handleUpdate() : handleSubmit();
            }
          }}
        >
          <S.InputContainer>
            <Label for="add-habit-row-name">Name</Label>
            <Input
              type="text"
              name="name"
              id="add-habit-row-name"
              placeholder="Name"
              value={habit.name}
              onChange={handleChange}
              tabIndex="0"
              onKeyDown={e => {
                if (e.key === 'Tab' && e.shiftKey) {
                  const lastEl = document.getElementById('add-habit-row-last');
                  setTimeout(() => {
                    lastEl.focus();
                  }, 5);
                }
              }}
              data-private
            />
            <S.HelperText>The name of your habit</S.HelperText>
          </S.InputContainer>
          <S.InputContainer>
            <Label for="frequency">Frequency</Label>
            <FrequencyModal
              id="frequency"
              isOpen={isFrequencyOpen}
              setIsOpen={setIsFrequencyOpen}
              habit={habit}
              setHabit={setHabit}
            />
            <S.HelperText>How often will you do it</S.HelperText>
          </S.InputContainer>
          <S.InputContainer>
            <Label for="category">Category</Label>
            <Input
              type="select"
              name="category_id"
              id="category"
              placeholder="Category"
              value={habit.category_id}
              onChange={handleChange}
            >
              {data &&
                [...new Set(data.categories.map(el => el.liability))].map(liability => (
                  <optgroup key={liability} label={`${liabilityToEmoji(liability)} ${liability}`}>
                    {data.categories
                      .filter(cat => cat.liability === liability)
                      .sort((a, b) => {
                        if (b.name === 'Other') {
                          return -1;
                        }
                        if (a.name !== 'Other') {
                          return 1;
                        }
                        return 0;
                      })
                      .map(category => (
                        <option value={category.id} key={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </optgroup>
                ))}
            </Input>
            <S.HelperText>Category related to the habit</S.HelperText>
          </S.InputContainer>
          <S.InputContainer>
            <Label for="priority">How much of a priority is this for you?</Label>
            <Input
              type="select"
              name="priority"
              id="priority"
              placeholder="priority"
              value={habit.priority}
              onChange={handleChange}
            >
              <option value="low">❕ Low</option>
              <option value="medium">❗️ Normal</option>
              <option value="high">‼️ High</option>
            </Input>
            <S.HelperText>This will impact the weight of your scores. </S.HelperText>
          </S.InputContainer>
          {!editingHabit && (
            <S.InputContainer>
              <Label id="label-commitment">{firstDaysOfWeek ? 'Your last' : 'This'} week's commitment</Label>
              <ScoreNumber
                habit={habit}
                currentScore={reviews[0].score}
                changeScore={v => handleScoreChange(v, 0)}
                newHabit
              />
              <S.HelperText>{firstDaysOfWeek ? 'Last' : 'This'} week's commitment to this habit.</S.HelperText>
            </S.InputContainer>
          )}

          {editingHabit ? (
            <S.ButtonsContainer editing>
              <S.SubmitButton disabled={loading} onClick={handleUpdate} color="primary" className="mr-2" w100>
                Update
              </S.SubmitButton>
              <S.SubmitButton disabled={loading} onClick={handleCancel} color="danger" className="mr-2" w100>
                Cancel
              </S.SubmitButton>
              <S.SubmitButton onClick={toggleHabit} w100>
                {habit.active ? 'Deactivate' : 'Enable'}
              </S.SubmitButton>
              <DeleteHabit
                id="add-habit-row-last"
                habit={habit}
                close={handleCancel}
                onKeyDown={e => {
                  if (e.key === 'Tab' && !e.shiftKey) {
                    const firstEl = document.getElementById('add-habit-row-name');
                    setTimeout(() => {
                      firstEl.focus();
                    }, 5);
                  }
                }}
              />
            </S.ButtonsContainer>
          ) : (
            <S.ButtonsContainer>
              <S.SubmitButton
                id="btn-confirm-new-habit"
                disabled={loading}
                onClick={handleSubmit}
                color="primary"
                className="mr-2"
                w100
              >
                Confirm
              </S.SubmitButton>
              <S.SubmitButton
                id="add-habit-row-last"
                disabled={loading}
                onClick={handleCancel}
                color="danger"
                w100
                onKeyDown={e => {
                  if (e.key === 'Tab' && !e.shiftKey) {
                    const firstEl = document.getElementById('add-habit-row-name');
                    setTimeout(() => {
                      firstEl.focus();
                    }, 5);
                  }
                }}
              >
                Cancel
              </S.SubmitButton>
            </S.ButtonsContainer>
          )}
        </S.NewHabitForm>
      )}
      <SubscriptionModal
        isOpen={subscriptionModalVisible}
        closeModal={() => setSubscriptionModalVisible(!subscriptionModalVisible)}
      />
    </S.Container>
  );
};

export default AddHabitRow;
