import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(55, 53, 47, 0.08) !important;
  height: 100% !important;
  padding: 0.5rem;
`;

export const InviteLink = styled.div`
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
`;

export const ShareButton = styled.button`
  border: none;
  height: 100%;
  width: 30px;
`;
