import styled from 'styled-components';
import React from 'react';

const NewMessageIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff0000;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  border-radius: 50px;
`;

const NewMessageIndicatorText = styled.div`
  font-size: 12px;
  color: white;
`;

export const NewMessageIndicator = ({ unreadCount }) => {
  if (!unreadCount) return null;

  return (
    <NewMessageIndicatorContainer>
      <NewMessageIndicatorText>{unreadCount}</NewMessageIndicatorText>
    </NewMessageIndicatorContainer>
  );
};
