import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Dropdown,
  DropdownMenu,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';

import { parseDayOfWeek, parseFrequency } from '../../../helpers/tracker-parsers/parsers';

import { durationOptions } from './data';
import * as S from './styled';

const FrequencyModal = ({ isOpen, setIsOpen, habit, setHabit }) => {
  const [duration, setDuration] = useState('30');
  const [repeat, setRepeat] = useState({
    type: 'everyday',
    days: [],
    times: 7,
  });
  const [time, setTime] = useState({
    value: 1,
    period: 'unspecified',
  });

  useEffect(() => {
    if (isOpen) {
      const firstInput = document.getElementById('frequency-dropdown');
      if (firstInput) {
        firstInput.focus();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (habit.frequency) {
      const { duration, repeat, time } = habit.frequency;
      setDuration(duration);
      setRepeat(repeat);
      setTime(time);
    }
  }, [habit]);

  const toggle = () => setIsOpen(!isOpen);

  const handleFrequency = () => {
    if ((repeat.type === 'daily' && repeat.days.length === 0) || (repeat.type === 'weekly' && repeat.times === 0)) {
      return toast.info('You have to select at least one day of the week.');
    }

    if (time.period === 'exact' && !time.start) {
      return toast.info('You have to select the starting time');
    }

    if (time.period === 'range' && (!time.start || !time.end)) {
      return toast.info('You have to select the time range.');
    }

    if (time.period === 'range') {
      const momentStart = moment(time.start, 'HH:mm');
      momentStart.add(duration, 'minute');
      if (momentStart.unix() > moment(time.end, 'HH:mm').unix()) {
        return toast.info('The End Time of your range needs to be greater than the sum of the Start Time + Duration.');
      }
    }

    setHabit({
      ...habit,
      frequency: {
        duration: Number(duration),
        repeat: {
          ...repeat,
          days: repeat.days.sort((a, b) => a - b),
          times: Number(repeat.times),
        },
        time: {
          ...time,
          value: Number(time.value),
        },
      },
    });
    toggle();
  };

  const handleDayClick = (day, isActive) => {
    const index = repeat.days.findIndex(d => d === day);

    if (isActive) {
      const repeatDaysCopy = [...repeat.days];
      repeatDaysCopy.splice(index, 1);
      setRepeat({ ...repeat, days: repeatDaysCopy });
    } else {
      const repeatDaysCopy = [...repeat.days];
      repeatDaysCopy.push(day);
      setRepeat({ ...repeat, days: repeatDaysCopy });
    }
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} style={{ width: '100%' }}>
      <S.DropdownButton className="form-control" tag="div" tabindex="0">
        <span>{!habit.frequency ? 'Choose frequency' : parseFrequency(habit.frequency)}</span>
        <S.DropdownIcon icon="chevron-down" className="text-1000 fs--2" />
      </S.DropdownButton>
      <DropdownMenu right className="p-2" id="frequency-dropdown">
        <S.Subtitle>Which days of the week?</S.Subtitle>
        <div className="d-flex flex-column mb-1">
          <FormGroup check>
            <Label check style={{ fontSize: '0.7rem' }}>
              <Input
                className="mt-0"
                type="radio"
                name="radio1"
                checked={repeat.type === 'everyday'}
                onChange={() =>
                  setRepeat({
                    type: 'everyday',
                    days: [],
                    times: 7,
                  })
                }
                tabIndex="0"
              />
              Everyday
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check style={{ fontSize: '0.7rem' }}>
              <Input
                className="mt-0"
                type="radio"
                name="radio1"
                checked={repeat.type === 'daily'}
                onChange={() => setRepeat({ ...repeat, type: 'daily' })}
                tabIndex="0"
              />
              Specific days
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check style={{ fontSize: '0.7rem' }}>
              <Input
                className="mt-0"
                type="radio"
                name="radio1"
                checked={repeat.type === 'weekly'}
                onChange={() => setRepeat({ ...repeat, type: 'weekly' })}
                tabIndex="0"
              />
              Number of days
            </Label>
          </FormGroup>
        </div>
        {repeat.type !== 'everyday' && (
          <div className="d-flex flex-column mb-2">
            <p className="mt-1 mb-1">
              {repeat.type === 'daily' ? 'choose days' : parseFrequency({ repeat: { times: repeat.times } }, true)}
            </p>
            <div className="d-flex mb-2">
              {repeat.type === 'daily'
                ? [...Array(7)].map((el, index) => {
                    const dayNumber = index + 1;
                    const dayInText = parseDayOfWeek(dayNumber);
                    const isDayActive = repeat.days ? repeat.days.includes(dayNumber) : false;
                    return (
                      <S.WeekDayBall
                        key={dayNumber}
                        active={isDayActive}
                        className="fs--2"
                        onClick={() => handleDayClick(dayNumber, isDayActive)}
                        onKeyDown={event => (event.keyCode === 32 ? handleDayClick(dayNumber, isDayActive) : undefined)}
                        tabIndex="0"
                      >
                        {dayInText}
                      </S.WeekDayBall>
                    );
                  })
                : [...Array(7)].map((el, index) => {
                    const numOfTimes = index + 1;
                    const isActive = repeat.times === numOfTimes;
                    return (
                      <S.WeekDayBall
                        key={numOfTimes}
                        active={isActive}
                        className="fs--2"
                        onClick={() => setRepeat({ ...repeat, times: numOfTimes })}
                        onKeyDown={event =>
                          event.keyCode === 32 ? setRepeat({ ...repeat, times: numOfTimes }) : undefined
                        }
                        tabIndex="0"
                      >
                        {numOfTimes}
                      </S.WeekDayBall>
                    );
                  })}
            </div>
          </div>
        )}

        <S.Subtitle>How many times a day?</S.Subtitle>
        <div className="d-flex flex-column mb-2">
          <FormGroup check>
            <Label check style={{ fontSize: '0.7rem' }}>
              <Input
                className="mt-0"
                type="radio"
                name="radio3"
                checked={time.value === 1}
                onChange={() => setTime({ ...time, value: 1 })}
              />
              Once
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check style={{ fontSize: '0.7rem' }}>
              <Input
                className="mt-0"
                type="radio"
                name="radio3"
                checked={time.value !== 1}
                onChange={() => setTime({ ...time, value: 2, period: 'unspecified' })}
              />
              Several times
            </Label>
          </FormGroup>
        </div>
        {time.value !== 1 && (
          <div className="d-flex flex-column mb-2">
            <Input
              required
              type="select"
              bsSize="sm"
              name="time"
              value={time.value}
              onChange={({ target }) => setTime({ ...time, value: target.value })}
              className="mb-1"
            >
              <option value="2">2x per day</option>
              <option value="3">3x per day</option>
              <option value="4">4x per day</option>
              <option value="5">5x per day</option>
            </Input>
          </div>
        )}

        {Number(time.value) === 1 && (
          <>
            <S.Subtitle>When?</S.Subtitle>
            <div className="d-flex flex-column mb-2">
              <Input
                required
                type="select"
                bsSize="sm"
                name="time_period"
                value={time.period}
                onChange={({ target }) => setTime({ ...time, period: target.value })}
                className="mb-1"
              >
                <optgroup label="Unspecified">
                  <option value="unspecified">Anytime</option>
                </optgroup>
                <optgroup label="Day periods">
                  <option value="Early morning">Early morning (6am-9am)</option>

                  <option value="Morning">Morning (9am-12pm)</option>

                  <option value="Afternoon">Afternoon (12-3pm)</option>

                  <option value="Late afternoon">Late afternoon (3-6pm)</option>

                  <option value="Evening">Evening (6-9pm)</option>

                  <option value="Late evening">Late evening (9-12am)</option>
                </optgroup>
                <optgroup label="Custom">
                  <option value="exact">At an exact time</option>

                  <option value="range">Within a time range</option>
                </optgroup>
              </Input>

              {time.period === 'exact' && (
                <InputGroup size="sm">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>From</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    type="time"
                    name="time_start"
                    value={time.start}
                    onChange={({ target }) => setTime({ ...time, start: target.value })}
                  />
                </InputGroup>
              )}

              {time.period === 'range' && (
                <>
                  <InputGroup size="sm" className="mb-1">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>From</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      type="time"
                      name="time_start"
                      value={time.start}
                      onChange={({ target }) => setTime({ ...time, start: target.value })}
                    />
                  </InputGroup>
                  <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>To</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      type="time"
                      name="time_end"
                      value={time.end}
                      onChange={({ target }) => setTime({ ...time, end: target.value })}
                      disabled={!time.start}
                    />
                  </InputGroup>
                </>
              )}
            </div>
          </>
        )}

        <S.Subtitle>For how long (duration)</S.Subtitle>

        <Input
          required
          type="select"
          bsSize="sm"
          name="duration"
          value={duration}
          onChange={({ target }) => setDuration(target.value)}
        >
          {durationOptions.map(el => (
            <option key={el.value} value={el.value}>
              {el.name}
            </option>
          ))}
        </Input>
        <p>
          <small>How long does it take to do it?</small>
        </p>

        <Button size="sm" onClick={toggle}>
          Cancel
        </Button>
        <Button
          size="sm"
          className="ml-1"
          color="primary"
          onClick={handleFrequency}
          onKeyDown={event => (event.keyCode === 32 ? handleFrequency() : undefined)}
        >
          Continue
        </Button>
      </DropdownMenu>
    </Dropdown>
  );
};

export default FrequencyModal;
