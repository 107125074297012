import styled from 'styled-components';
import { Input, FormText, ModalHeader } from 'reactstrap';

export const Header = styled(ModalHeader)`
  h5 {
    font-size: 1.75rem;
  }
`;

export const ReviewInput = styled(Input)`
  width: 100%;
  min-height: 100px;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  padding-top: 0.5rem;

  /* &::placeholder {
    font-size: 1rem;
  } */
`;

export const HelperText = styled(FormText)`
  font-size: 0.8rem;
  margin-top: 0;
`;
