import styled from 'styled-components';
import { colorParser } from '../../../helpers/tracker-parsers/parsers';

export const ScoreContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: ${props => !props.newHabit && !props.newReview && 'column'};
  padding: ${props => (props.newReview ? '0.5rem 0' : '0.5rem 1rem')};

  background: ${props => props.newHabit && 'rgba(55, 53, 47, 0.08)'};
  height: ${props => props.newHabit && 'calc(2em + .8rem + 2px)'};
  border-radius: 0.25rem;
  width: ${props => props.newHabit && '100%'};

  div.title {
    height: 0.7rem;
    line-height: 0.7rem;
    font-size: 0.7rem;
  }

  div.numbers {
    display: grid;
    margin-top: -5px;
    grid-template-columns: ${props => (props.newHabit ? 'repeat(5, 1fr)' : 'repeat(6, 1fr)')};
    justify-items: center;
    align-items: center;
    width: ${props => (props.newHabit ? '9rem' : '10rem')};
  }
`;

export const ScoreLabel = styled.div`
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: ${props => !props.newHabit && '0.25rem'};
  margin-left: ${props => props.newHabit && '0.25rem'};
  margin-left: ${props => props.newReview && '0.5rem'};
  min-width: ${props => props.newHabit && '8rem'};
  text-align: ${props => props.newHabit && 'center'};
  min-width: ${props => props.newReview && '9rem'};

  color: ${props => props.currentScore !== false && colorParser(props.currentScore, true)};
  color: ${props => props.hoverScore !== false && colorParser(props.hoverScore, true)};

  & > .not-selected {
    font-weight: 300;
    font-style: italic;

    display: block;
    width: 100%;
    text-align: center;
  }

  .hint {
    font-weight: normal;
    font-size: 0.8em;
  }
`;

export const ScoreBall = styled.div`
  line-height: 0;
  height: 1.5rem;
  width: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  cursor: pointer;

  background: ${props => props.currentScore !== false && colorParser(props.currentScore)};
  background: ${props => props.hoverScore !== false && colorParser(props.hoverScore)};

  .emoji {
    display: block;
    font-family: 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 0;
  }
`;
