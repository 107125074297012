import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Form, Row } from 'reactstrap';
import { toast } from 'react-toastify';

import { useAuth } from '../../context/AuthContext';
import api from '../../services/api';

import FalconCardHeader from '../common/FalconCardHeader';
import FormGroupInput from '../common/FormGroupInput';
import Flex from '../common/Flex';

const SettingsTracker = ({ user }) => {
  const { updateUser } = useAuth();
  const [tracker, setTracker] = useState({ start_day: 1, review_day: 7 });

  useEffect(() => {
    const tracker = JSON.parse(user.tracker_settings);
    setTracker(tracker);
  }, []);

  const handleTrackerSettings = async e => {
    e.preventDefault();

    try {
      await api.patch('/user', {
        tracker_settings: JSON.stringify(tracker),
      });
      updateUser('tracker_settings', JSON.stringify(tracker));
      toast.success('Success.');
      window.analytics.track('Updated Reflection settings');
    } catch (err) {
      toast.error('There is an error in your request.', { position: 'top-center' });
    }
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Tracker Settings" light={false} />
      <CardBody className="bg-light">
        <Form onSubmit={handleTrackerSettings}>
          <Row>
            <Col xs={12}>
              <FormGroupInput
                type="select"
                id="start_day"
                name="start_day"
                label="Select the start of the week day"
                value={tracker.start_day}
                onChange={({ target }) =>
                  setTracker({
                    ...tracker,
                    start_day: target.value,
                    review_day: Number(target.value) === 7 ? '6' : '7',
                  })
                }
              >
                <option value="7">Sunday</option>
                <option value="1" selected>
                  Monday
                </option>
              </FormGroupInput>
            </Col>
            <Col xs={12}>
              <FormGroupInput
                type="select"
                id="review_day"
                name="review_day"
                label="Select your review day"
                value={tracker.review_day}
                onChange={({ target }) => setTracker({ ...tracker, review_day: target.value })}
              >
                <option value="5">Friday</option>
                <option value="6">Saturday</option>
                <option value="7" selected>
                  Sunday
                </option>
              </FormGroupInput>
            </Col>
            <Col tag={Flex} xs={12} justify="end">
              <Button id="btn-save-tracker" color="primary" type="submit">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default SettingsTracker;
