import React from 'react';

import { colorParser, statusCalculator } from '../../../helpers/tracker-parsers/parsers';

import * as S from './styled';

const HabitStatus = ({ reviews }) => {
  return (
    <>
      <S.ScoreBall style={{ background: colorParser(statusCalculator(reviews)) }} className="updateLog" />{' '}
      <span className="font-weight-bold updateLog" style={{ color: colorParser(statusCalculator(reviews)) }}>
        {statusCalculator(reviews)}
      </span>
    </>
  );
};

export default HabitStatus;
