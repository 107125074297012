import React from 'react';
import { Popover, PopoverBody } from 'reactstrap';

import * as S from './styled';

const ReviewInsights = ({ isOpen, toggleOpen, target, notes, insights }) => {
  return (
    <S.CustomPopover placement="top" isOpen={isOpen} target={target} toggle={toggleOpen}>
      <PopoverBody>
        {notes && `Notes: ${notes}`}
        {notes && insights && <br />}
        {insights && `Insights: ${insights}`}
      </PopoverBody>
    </S.CustomPopover>
  );
};

export default ReviewInsights;
