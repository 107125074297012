import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  margin-left: 0.5rem;

  ${media.lessThan('small')`
      margin: 0 0 2rem 0;
    
    `}
`;

export const Greeting = styled.div`
  font-size: 2rem;
  font-weight: 600;
`;

export const ReviewInfo = styled.div`
  font-size: 1rem;
  font-weight: normal;
  color: ${props => (props.hasCTA ? '#e06666' : 'grey')};

  button {
    font-size: 1rem;
    font-weight: bold;
    color: ${props => (props.hasCTA ? '#e06666' : 'grey')};
    border: none;
    background: transparent;
    padding: 0;
    text-decoration: underline;
    margin-left: 0.5rem;
  }
`;
