import React, { useState, useEffect } from 'react';
import * as S from './styled';
import { useChatContext } from 'stream-chat-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default function CustomChannelHeader(props) {
  const { title, isOnline, setVisible, visible } = props;

  const [channelState, setChannelState] = useState(null);

  const { channel } = useChatContext();

  useEffect(() => {
    if (channel) {
      setChannelState(channel);
    }
  }, []);

  useEffect(() => {
    if (channelState !== channel) {
      setChannelState(channel);
    }
    return () => {
      setChannelState(null);
      setVisible(false);
    };
  }, [channel]);

  return (
    <div className="str-chat__header-livestream">
      <S.ChannelInfoWrapper>
        <p className="str-chat__header-livestream-left--title">{title}</p>
        {!!isOnline && <S.ChannelInfoStatus>online</S.ChannelInfoStatus>}
      </S.ChannelInfoWrapper>
      <S.NewChatButton onClick={() => setVisible(!visible)}>
        <FontAwesomeIcon icon={faBars} />
      </S.NewChatButton>
    </div>
  );
}
