import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 18rem;

  ${media.lessThan('medium')`
  display: none;
`}
`;

export const Hour = styled.div`
  font-size: ${props => (props.partner ? '0.9rem' : '1rem')};
  line-height: ${props => (props.partner ? '1rem' : '1.2rem')};
  font-weight: bold;
  color: ${props => props.periodColor};

  span {
    font-size: 0.6rem;
  }
`;
