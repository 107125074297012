import styled from 'styled-components';
import media from 'styled-media-query';

export const Insights = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  font-size: 0.9rem;
  width: calc(100% - 82rem);
  min-height: 3.5rem;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: horizontal;

  ${media.lessThan('huge')`
      display: none;
    `}

  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
  }
`;
