import styled from 'styled-components';
import media from 'styled-media-query';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: transparent;
  padding: 1rem;
  text-align: center;

  ${media.between('960px', '1280px')`{
  max-width: 1280px;
  width: 75%;
`}
`;

export const NewChatButton = styled.button`
  font-size: 24px;
  font-weight: bold;
  visibility: hidden;
  outline: none;
  border: none;
  background: transparent;

  ${media.lessThan('960px')`{
    visibility:visible;
  }`}
`;
