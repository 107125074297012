import React from 'react';
import { Button, Nav } from 'reactstrap';
import ProfileDropdown from './ProfileDropdown';
import { useAuth } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TopNavRightSideNavItem = () => {
  const { isPrivacyActive, activatePrivacy, disablePrivacy } = useAuth();

  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      <Button
        onClick={isPrivacyActive ? disablePrivacy : activatePrivacy}
        color="link"
        size="sm"
        className="mr-2 nav-link font-weight-normal btn-privacy"
      >
        Turn {isPrivacyActive ? 'off' : 'on'} Privacy mode <FontAwesomeIcon icon="user-secret" className="ml-1" />
      </Button>
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
