import React from 'react';

import { LogsProvider } from '../../context/LogsContext';

import StatsSection from '../../components/stats/StatsSection';
import GuideBanner from '../../components/GuideBanner';
import StandardTable from '../../components/tracker/StandardTable';
import PartnerTable from '../../components/tracker/PartnerTable';

export default function MyDayView() {
  return (
    <>
      <StatsSection goToReview={false} />
      <GuideBanner />
      <LogsProvider>
        <StandardTable />
        <PartnerTable />
      </LogsProvider>
    </>
  );
}
