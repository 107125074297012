import React, { useEffect, useState } from 'react';
import { ChatProvider } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import { useAuth } from './AuthContext';
import { useData } from './DataContext';
import api from '../services/api';

export default function ChatContext({ children }) {
  const { user } = useAuth();

  const { setUnreadCount } = useData();

  const [client, setClient] = useState(null);

  const [isConnected, setIsConnected] = useState(false);

  const [channel, setChannel] = useState('');

  const listPartners = async () => {
    try {
      const res = await api.get('/partners');
      return res.data;
    } catch (e) {
      throw new Error(e);
    }
  };

  const connectUser = async () => {
    try {
      const streamChatClient = new StreamChat(process.env.REACT_APP_STREAM_CHAT_API_KEY);
      const res = await streamChatClient.connectUser(
        {
          id: user.id.toString(),
          name: user.name,
        },
        user.customToken
      );
      //if (res && res.me) setUnreadCount(res.me.total_unread_count);
      if (res && res.me) setUnreadCount(res.me.unread_channels);
      if (res.me.unread_channels > 0) {
        document.title = `(${res.me.unread_channels}) Weekup | Your weekly habits tracker`;
      }
      setClient(streamChatClient);
      setIsConnected(true);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPartnersAndUpsert = async () => {
    try {
      const partners = await listPartners();
      upsertChannels(partners);
    } catch (e) {
      console.log(e);
    }
  };

  const upsertChannels = async partners => {
    try {
      for (const partner of partners) {
        const channel = client.channel('messaging', {
          members: [user.id.toString(), partner.id.toString()],
        });
        await channel.create();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const disconnectUser = async () => {
    try {
      setIsConnected(false);
      await client.disconnectUser();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!isConnected && user) {
      connectUser();
    }
    return () => {
      if (client && isConnected) disconnectUser();
    };
  }, [user]);

  useEffect(() => {
    if (isConnected) {
      fetchPartnersAndUpsert();
    }
  }, [isConnected]);

  client !== null &&
    client.on(event => {
      if (event.total_unread_count !== undefined) {
        //console.log(event.total_unread_count);
        //setUnreadCount(event.total_unread_count);
      }
      if (event.unread_channels !== undefined) {
        setUnreadCount(event.unread_channels);
        if (event.unread_channels > 0) {
          document.title = `(${event.unread_channels}) Weekup | Your weekly habits tracker`;
        } else {
          document.title = `Weekup | Your weekly habits tracker`;
        }
      }
    });

  return (
    <ChatProvider
      value={{
        client,
        channel,
        setActiveChannel: setChannel,
        connectUser,
      }}
    >
      {children}
    </ChatProvider>
  );
}
