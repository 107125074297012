import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${props => props.bg};

  width: 100%;
  height: 4rem;
  padding: 1.25rem;

  font-size: 1.1rem;
  line-height: 1.1rem;

  display: flex;
  align-items: center;

  span {
    margin-right: 0.5rem;
    font-size: 1.25rem;
  }

  button {
    border: none;

    font-weight: normal;
    font-size: 1rem;
    margin-left: 0.5rem;

    color: #df3d3e;
    background: none;

    cursor: pointer;
    padding: 0 0 1px 0;
    border-bottom: 1px solid #df3d3e;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      color: #748194;
      border-bottom: 1px solid #748194;
      opacity: 0.8;
      cursor: not-allowed;
    }
  }
`;
