import React, { useState, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getReviewDaysRange } from '../../../helpers/tracker-parsers/helpers';

import { useAuth } from '../../../context/AuthContext';
import { useTable } from '../../../context/TableContext';

import * as S from './styled';
import { useData } from '../../../context/DataContext';

const Greetings = ({ goToReview }) => {
  const history = useHistory();
  const { user } = useAuth();
  const { data } = useData();
  const { reviewData, hasHabits } = useTable();

  const habits = data?.habits ?? [];
  const settings = user ? JSON.parse(user.tracker_settings) : false;

  const [daysToReview, setDaysToReview] = useState(-1);

  useLayoutEffect(() => {
    const calculateDays = () => {
      const todayWeekDay = moment().isoWeekday();
      const reviewWeekDay = Number(settings.review_day);

      let diff;
      // if we haven't yet passed the day of the week that I need:
      if (todayWeekDay <= reviewWeekDay) {
        // then just give me this week's instance of that day
        diff = moment()
          .isoWeekday(reviewWeekDay)
          .diff(moment(), 'days');
      } else {
        // otherwise, give me *next week's* instance of that same day
        diff = moment()
          .add(1, 'weeks')
          .isoWeekday(reviewWeekDay)
          .diff(moment(), 'days');
      }

      setDaysToReview(diff);
    };

    settings && calculateDays();
  }, [settings]);

  const firstName = fullName => {
    const words = fullName.split(' ');
    return words[0];
  };

  const isAllowedToReview = () => {
    const { missedLastReview, alreadyReviewThisWeek } = reviewData;

    if (missedLastReview) return true;

    if (!alreadyReviewThisWeek && daysToReview <= 2) return true;

    return false;
  };

  const defineReviewInfo = () => {
    if (reviewData.missedLastReview) {
      return `You missed your reflection for ${getReviewDaysRange(true, user.tracker_settings)}.`;
    }
    if (!reviewData.alreadyReviewThisWeek && daysToReview > 0) {
      return `Your reflection for ${getReviewDaysRange(false, user.tracker_settings)} is due in ${daysToReview} day${
        daysToReview > 1 ? 's' : ''
      }.`;
    }
    if (!reviewData.alreadyReviewThisWeek && daysToReview === 0)
      return `Your reflection for ${getReviewDaysRange(false, user.tracker_settings)} is due today.`;
    if (reviewData.alreadyReviewThisWeek)
      return `Your reflection for ${getReviewDaysRange(false, user.tracker_settings)} has been already submitted.`;
  };

  const shouldShowGreetings = Boolean(
    habits.filter(h => {
      const currentWeekYear = Number(moment().format('WW'));

      const createdAt = moment(h.created_at);
      const dayCreated = Number(createdAt.format('E'));
      const weekCreated = Number(createdAt.format('WW'));

      const createdOnFirstDays = dayCreated === 1 || dayCreated === 2;

      if (createdOnFirstDays || currentWeekYear !== weekCreated) {
        return true;
      }
    }).length
  );

  const allowedToReview = isAllowedToReview();

  return (
    <S.Wrapper>
      <S.Greeting className="text-900">
        Hey {firstName(user.name)}, it's {moment().format('ddd[,] MMMM Do')}.
      </S.Greeting>
      {shouldShowGreetings && hasHabits && typeof daysToReview === 'number' && (
        <S.ReviewInfo hasCTA={allowedToReview}>
          {defineReviewInfo()}{' '}
          {allowedToReview && (
            <button
              onClick={
                goToReview
                  ? goToReview
                  : () => {
                      window.analytics.track('Started Reflection');
                      history.push({
                        pathname: '/allhabits',
                        state: { reviewTime: true },
                      });
                    }
              }
            >
              Start Reflection <FontAwesomeIcon icon="arrow-right" className="ml-1 fs--2" />
            </button>
          )}
        </S.ReviewInfo>
      )}
    </S.Wrapper>
  );
};

export default Greetings;
