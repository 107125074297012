import React from 'react';

import { textParser, colorParser, roundScore } from '../../../helpers/tracker-parsers/parsers';

import * as S from './styled';

const StatsCard = ({ score, improvement }) => {
  const parseValueToClass = value => {
    if (value > 0) return '#93c47d';
    if (value < 0) return '#e06666';
    return '#c4c4c4';
  };

  return (
    <S.Wrapper>
      <S.Score>
        <h3 style={{ color: colorParser(roundScore(score)) }}>{score ? Math.round(score) : '--'}</h3>
      </S.Score>
      <S.Caption>
        <div className="score">
          Your last week score is{' '}
          <span style={{ color: colorParser(roundScore(score)) }}>{textParser(roundScore(score))}</span>
        </div>
        <div className="improvement" style={{ color: parseValueToClass(improvement.number) }}>
          {improvement.number}
          {improvement.style} since last week
        </div>
      </S.Caption>
    </S.Wrapper>
  );
};

export default StatsCard;
