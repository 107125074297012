import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  width: 100vw;
  height: 4rem;
  margin-left: -15px;
  padding-left: 1.5rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: white;
  background-color: #1a73e8;

  ${media.lessThan('medium')`
    display: none; 
  `}

  a {
    color: #fff;
    border: 1px solid #fff;
    cursor: pointer;

    padding: 6px 8px;
    margin: 0 1rem;

    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }

  button {
    border: none;
    background: none;
    text-decoration: underline;
    color: #fff;
  }
`;
