import styled from 'styled-components';

export const NotificationCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  margin: 2px 0;
`;

export const NotificationCheckboxLabel = styled.div`
  font-size: 12px;
  /*margin-left: 4px;*/
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
