import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

import * as S from './styled';

export default function HabitNotes({ habit, insight }) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const toggle = () => setIsTooltipOpen(!isTooltipOpen);

  return (
    <S.Insights className="updatelog">
      <p id={`insight-habit-${habit.id}`}>{insight}</p>
      {insight !== 'There is no note from last week.' && (
        <Tooltip placement="left" isOpen={isTooltipOpen} target={`insight-habit-${habit.id}`} toggle={toggle}>
          {insight}
        </Tooltip>
      )}
    </S.Insights>
  );
}
