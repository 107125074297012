import api from './api';

export const emailLogin = (email, password, fcmToken) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/auth/email`, { email, password, fcm_token: fcmToken })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const googleLogin = googleData => {
  return new Promise((resolve, reject) => {
    api
      .post('/auth/google', googleData)
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const facebookLogin = facebookData => {
  return new Promise((resolve, reject) => {
    api
      .post('/auth/facebook', facebookData)
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const verifyToken = async token => {
  return new Promise((resolve, reject) => {
    api
      .post(`/auth/refresh`, {
        refresh_token: token,
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};
