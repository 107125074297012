import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: ${props => (props.isLast ? '0' : '1.8rem')};
`;

const Title = styled.h3`
  font-size: 0.9rem;
  margin-left: ${props => (props.ml ? props.ml : '0.5rem')};
  color: ${props => (props.color ? props.color : 'grey')};
`;

const TitleWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 1px;
`;

const ScoreTextWrapper = styled.div`
  padding: 0 8px;
  border-radius: 16px;
  background: white;
  margin-left: 8px;
  margin-top: -8px;
`;

const ScoreText = styled.h3`
  font-size: 0.85rem;
  margin: 2px 0 0 0;
  line-height: 1.5;
  color: ${props => (props.color ? props.color : 'grey')};
`;

export { Wrapper, Title, TitleWrapper, ScoreTextWrapper, ScoreText };
