import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import * as auth from '../services/auth';
import api from '../services/api';
import seedSampleHabits from '../helpers/seedSampleHabits';
import LogRocket from 'logrocket';
import firebase from '../firebase/config';

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);

  return context;
};

export const AuthProvider = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);

  const persistedFcmToken = localStorage.getItem('fcmToken') ?? null;

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [invite, setInvite] = useState(null);
  const [fcmToken, setFcmToken] = useState(persistedFcmToken);
  const signed = !!user;

  const notificationPermission = Notification.permission;

  const [isPrivacyActive, setIsPrivacyActive] = useState(false);

  useEffect(() => {
    if (user) {
      const inviteCookie = Cookies.get('invite');
      if (inviteCookie) {
        setInvite(JSON.parse(inviteCookie));
      }
    }
  }, [user]);

  const activatePrivacy = () => {
    setIsPrivacyActive(true);
    localStorage.setItem('weekUpPrivacyMode', true);
    window.analytics.track(`Turned on Privacy Mode`);
  };
  const disablePrivacy = () => {
    setIsPrivacyActive(false);
    localStorage.removeItem('weekUpPrivacyMode');
    window.analytics.track(`Turned off Privacy Mode`);
  };

  useEffect(() => {
    const privacyMode = localStorage.getItem('weekUpPrivacyMode');
    if (privacyMode) {
      setIsPrivacyActive(true);
    }
  }, []);

  const getUserAndLogTimezone = () => {
    return new Promise((resolve, reject) => {
      api
        .get('/user')
        .then(response => {
          const onlyPartner = !response.data.hasHabits && response.data.isPartner;
          setUser({ ...response.data, onlyPartner });
          resolve(response);

          const settings = JSON.parse(response.data.tracker_settings);
          const timezoneOffset = new Date().getTimezoneOffset();

          if (!settings.timezoneOffset || timezoneOffset !== settings.timezoneOffset) {
            api.patch('/user', {
              tracker_settings: JSON.stringify({ ...settings, timezoneOffset }),
            });
          }
        })
        .catch(err => {
          console.log('error -> await getUserAndLogTimezone');
          console.log('err', err);
          reject(err);
        });
    });
  };

  const handlePartnerToken = async partnerToken => {
    if (!signed) {
      localStorage.setItem('partnerToken', partnerToken);
      return toast.info("You need to be logged in to accept your friend's invite.");
    }

    try {
      setLoading(true);
      await api.get(`/match-partner?token=${partnerToken}`);
      localStorage.removeItem('partnerToken');
      const response = await api.get('/user');
      setUser(response.data);
      setLoading(false);
      toast.success("You've been matched as an Accountability Partner!");
    } catch (err) {
      setLoading(false);
      toast.error('Error matching Accountability Partner token');
    }
  };

  const cookieDomain = process.env.REACT_APP_API_URL === 'http://localhost:3333' ? '' : '.weekup.io';

  const initializePendo = () => {
    console.log('Initializing Pendo...');

    if (window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: user.id, // Required if user is logged in
        },
      });
      console.log('Pendo initialized', {
        visitor: {
          id: user.id,
        },
      });
    } else {
      setTimeout(initializePendo, 500);
    }
  };

  const initializeLogRocket = () => {
    console.log('Initializing LogRocket...');

    LogRocket.identify(user.id, {
      name: user.name,
      email: user.email,
    });
  };

  useEffect(() => {
    if (user) {
      initializePendo();
      initializeLogRocket();
    }

    let partnerToken = urlParams.get('partnerToken');
    if (!partnerToken) {
      partnerToken = localStorage.getItem('partnerToken');
    }

    partnerToken && handlePartnerToken(partnerToken);
  }, [signed]);

  const updateUser = (name, payload) => {
    setUser({ ...user, [name]: payload });

    if (name === 'name') {
      window.analytics.identify(user.id, {
        name: payload,
        email: user.email,
      });
    }
  };

  useEffect(() => {
    const loadStorageData = async token => {
      setLoading(true);
      try {
        api.defaults.headers['Authorization'] = `Bearer ${token}`;
        // set user in state
        await getUserAndLogTimezone();
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
        Cookies.remove('userToken');
        return;
      }
    };

    const token = Cookies.get('userToken');
    if (token) {
      loadStorageData(token);
    }
  }, []);

  const magicLinkLogin = async token => {
    setLoading(true);
    try {
      // add token to api requests and cookies
      api.defaults.headers['Authorization'] = `Bearer ${token}`;
      Cookies.set('userToken', token, {
        domain: cookieDomain,
        sameSite: 'none',
        secure: true,
        expires: 60,
      });

      // set user in state
      const response = await getUserAndLogTimezone();
      setLoading(false);
      window.analytics.identify(response.data.id, {
        name: response.data.name,
        email: response.data.email,
      });
      window.analytics.track('User logged in', {
        accountType: 'Email',
      });
    } catch (err) {
      setLoading(false);
      setError(err);
      toast.error(err?.response?.data ?? 'Error', { position: 'top-center' });
      return err;
    }
  };

  /*
  if user is not logged in and wants to enable push notifications it will work because we send tokens while we login.
  if user is logged in but didnt give permissions for notifications it will work because we send tokens again
  */
  const enablePushNotifications = async () => {
    try {
      if (notificationPermission === 'default') {
        await Notification.requestPermission();
        const fcmTokenInstance = await firebase.messaging().getToken();
        if (user !== null) {
          await api.post('/auth/addfcm', { fcm_token: fcmTokenInstance });
        }
        localStorage.setItem('fcmToken', fcmTokenInstance);
        setFcmToken(fcmTokenInstance);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    enablePushNotifications();
  }, [user]);

  const emailLogin = async loginData => {
    setLoading(true);
    try {
      // add token to api requests and cookies
      api.defaults.headers['Authorization'] = `Bearer ${loginData.token}`;
      Cookies.set('userToken', loginData.token, {
        domain: cookieDomain,
        sameSite: 'none',
        secure: true,
        expires: 60,
      });
      // set user in state
      const response = await getUserAndLogTimezone();
      setLoading(false);
      window.analytics.identify(response.data.id, {
        name: response.data.name,
        email: response.data.email,
      });
      window.analytics.track('User logged in', {
        accountType: 'Email',
      });
    } catch (err) {
      setLoading(false);
      setError(err);
      toast.error(err?.response?.data ?? 'Error', { position: 'top-center' });
      return err;
    }
  };

  const googleLogin = async googleData => {
    setLoading(true);
    try {
      const { data } = await auth.googleLogin({ ...googleData, fcm_token: fcmToken });
      // if just registed, seed sample habits
      if (data.justRegistered) {
        await seedSampleHabits(data.token);
      }
      // add token to api requests and cookies
      api.defaults.headers['Authorization'] = `Bearer ${data.token}`;
      Cookies.set('userToken', data.token, {
        domain: cookieDomain,
        sameSite: 'none',
        secure: true,
        expires: 60,
      });
      // set user in state
      const response = await getUserAndLogTimezone();
      setLoading(false);
      // analytics
      window.analytics.identify(response.data.id, {
        name: response.data.name,
        email: response.data.email,
      });
      if (data.justRegistered) {
        window.analytics.track('User registered', {
          accountType: 'Google',
        });
      }
      window.analytics.track('User logged in', {
        accountType: 'Google',
      });
    } catch (err) {
      console.log('err', err);
      setLoading(false);
      setError(err);
      toast.error(err?.response?.data ?? 'Error', { position: 'top-center' });
      return err;
    }
  };

  const facebookLogin = async facebookData => {
    setLoading(true);
    try {
      const { data } = await auth.facebookLogin({ ...facebookData, fcm_token: fcmToken });
      // if just registed, seed sample habits
      if (data.justRegistered) {
        await seedSampleHabits(data.token);
      }
      // add token to api requests and cookies
      api.defaults.headers['Authorization'] = `Bearer ${data.token}`;
      Cookies.set('userToken', data.token, {
        domain: cookieDomain,
        sameSite: 'none',
        secure: true,
        expires: 60,
      });
      // set user in state
      const response = await getUserAndLogTimezone();
      setLoading(false);
      // analytics
      window.analytics.identify(response.data.id, {
        name: response.data.name,
        email: response.data.email,
      });
      if (data.justRegistered) {
        window.analytics.track('User registered', {
          accountType: 'Facebook',
        });
      }
      window.analytics.track('User logged in', {
        accountType: 'Facebook',
      });
    } catch (err) {
      setLoading(false);
      setError(err);
      toast.error(err?.response?.data ?? 'Error', { position: 'top-center' });
      return err;
    }
  };

  const togglePushNotifications = async () => {
    try {
      if (fcmToken) {
        await firebase.messaging().deleteToken();
        setFcmToken(null);
        localStorage.removeItem('fcmToken');
        const newTokenInstance = await firebase.messaging().getToken();
        setFcmToken(newTokenInstance);
        localStorage.setItem('fcmToken', newTokenInstance);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const logout = () => {
    setUser(null);
    Cookies.remove('userToken', { domain: cookieDomain });
    togglePushNotifications();
    delete api.defaults.headers['Authorization'];
    window.analytics.track('User logged out');
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        invite,
        setInvite,
        updateUser,
        signed,
        magicLinkLogin,
        emailLogin,
        googleLogin,
        facebookLogin,
        logout,
        loading,
        error,
        isPrivacyActive,
        activatePrivacy,
        disablePrivacy,
        setFcmToken,
        fcmToken,
        togglePushNotifications,
        notificationPermission,
        enablePushNotifications,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
