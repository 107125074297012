import styled from 'styled-components';
import media from 'styled-media-query';
import { Button } from 'reactstrap';

export const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
  max-width: 100vw;
  position: relative;

  &:after {
    display: ${props => (props.privacyMode ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 20px;
    backdrop-filter: blur(4px);
    transform: scale(1.05);
  }

  @-moz-document url-prefix() {
    &:after {
      backgrond: rgba(255, 255, 255, 0.95);
    }
  }
`;

export const DisablePrivacyMode = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px,
    rgba(0, 0, 0, 0.4) 0px 15px 40px 0px;
`;

export const Title = styled.h4`
  text-transform: capitalize;
  text-align: center;
`;

export const SubtitlesContainer = styled.div`
  display: flex;
  margin-left: 0.5rem;

  h5#weeks-subtitle {
    min-width: ${props => (props.table !== 'advanced' ? '20rem' : `${props.advWeeks * 5}rem`)};
    text-align: left;
    font-weight: 200;
    margin: ${props => (props.table === 'advanced' ? '0 0 0 auto' : '0 15px 0 auto')};

    ${media.lessThan('large')`
      display: ${props => (props.table === 'review' ? 'none' : 'block')};;
    `}

    ${media.lessThan('small')`
      display: none;
    `}
  }

  h5#review-subtitle {
    width: 45rem;
    text-align: left;
    font-weight: 200;
  }
`;

export const HabitsSubtitle = styled.h5`
  min-width: ${props => (props.table === 'review' ? '20rem' : '14rem')};
  width: ${props => (props.table === 'review' ? '20rem' : '100%')};
  padding-left: 0.5rem;
  font-weight: 200;
  margin-bottom: 0;
  font-size: 1.5rem;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  padding: 0.25rem 0;
  font-weight: 600;
  color: #344050;
  min-height: 1.5rem;

  div.weeks {
    display: flex;
    align-items: center;
    font-size: 0.6rem;
    margin-left: auto;

    border-right: ${props => props.table === 'review' && '15px solid #f4f4f4'};

    span {
      width: 5rem;
      text-align: center;
    }

    ${media.lessThan('large')`
      display: ${props => (props.table === 'review' ? 'none' : 'flex')};;
    `}

    ${media.lessThan('small')`
      display: none;
    `}
  }

  div.reviews {
    width: 45rem;
    font-size: 0.65rem;
    text-align: center;
  }
`;

export const Separator = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 1.5rem;

  h5 {
    font-size: 0.9rem;
    margin-right: 0.5rem;
    margin-bottom: 0;

    background-color: #666666;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: rgba(245, 245, 245, 0.5) 1px 1px 1px;
  }

  hr {
    flex-grow: 1;
  }
`;

export const Row = styled.div`
  display: flex;

  border-radius: 25px;
  margin: 0 0 0.5rem 0.5rem;

  background: white;

  filter: ${props => props.finished && 'opacity(0.4)'};

  & > div {
    background: ${props => props.table === 'review' && 'white'};
  }

  div.frequency {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 10rem;
    font-size: 0.8rem;
    color: 'grey';

    ${media.lessThan('large')`
    display: none;
  `}
  }

  div.hour {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 10rem;
    font-size: 0.9rem;
    color: ${props => props.periodColor};
    font-weight: bold;

    ${media.lessThan('large')`
    display: none;
  `}
  }

  div.category {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 14rem;

    ${media.lessThan('large')`
    display: none;
  `}
  }

  div.status {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 12rem;
    min-height: 3.5rem;

    ${media.lessThan('small')`
    padding-left: 0.5rem;
   
    width: auto;
min-width: 50%;
max-width: 50%;
    `}
  }

  div.partner {
    display: flex;
    align-items: center;

    width: 16rem;
    min-height: 3.5rem;

    ${media.lessThan('large')`
      display: none;
    `}
  }

  div.insights {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    font-size: 0.9rem;
    width: 17rem;
    min-height: 3.5rem;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: horizontal;

    ${media.lessThan('large')`
      display: none;
    `}
  }

  div.spacing {
    width: calc(100% - 99rem);

    ${media.lessThan('large')`
    width: calc(100% - 66rem);
    `}
  }

  div.scores {
    display: flex;
    margin-left: auto;
    border-left: 15px solid #f4f4f4;
    border-right: ${props => props.table === 'review' && '15px solid #f4f4f4'};

    ${media.lessThan('large')`
      display: ${props => (props.table === 'review' ? 'none' : 'flex')};;
    `}

    ${media.lessThan('small')`
      display: none;
    `}
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: ${props => props.table === 'review' && 'flex-end'};

  ${media.lessThan('small')`
      display: none;
    `}
`;

export const ReviewsFooter = styled.div`
  min-height: 3rem;

  div {
    margin-left: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #2c7be5;
    color: white;
    font-weight: 600;

    height: 100%;
    width: 30rem;
    padding: 0.5rem 0;
    border-radius: 25px;

    transition: filter 0.3s;
    cursor: pointer;

    &:hover {
      filter: brightness(0.9);
    }

    &.disabled {
      cursor: not-allowed;
      color: rgba(55, 53, 47, 0.4);
      background: rgba(55, 53, 47, 0.08);
    }

    &.disabled:hover {
      filter: none;
    }
  }
`;

/**
 * 
 * white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block
 */
