import React from 'react';

import * as S from './styled';

export default function Loading() {
  return (
    <S.Wrapper>
      <S.DotFlashing />
    </S.Wrapper>
  );
}
