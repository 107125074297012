import styled from 'styled-components';
import media from 'styled-media-query';
import { CustomInput, Badge } from 'reactstrap';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 1.5rem;

  min-height: 3.5rem;
  min-width: ${props => (props.table === 'review' ? '19rem' : '14rem')};
  max-width: ${props => (props.table === 'review' ? '19rem' : '14rem')};

  border-bottom-left-radius: ${props => props.table === 'review' && '25px'};
  border-top-left-radius: ${props => props.table === 'review' && '25px'};

  ${media.lessThan('small')`
    padding: 0 0.5rem 0 1.5rem;

      min-width: 50%;
      max-width: 50%;
    `}
`;

export const Checkbox = styled(CustomInput)`
  margin: auto 0.75rem auto 0;
  cursor: pointer;

  * {
    cursor: pointer;
  }

  input,
  label:before,
  label:after {
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
  }

  label:before {
    border: 2px solid #4d5969;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  user-select: none;

  div.name-box {
    max-width: 100%;
    line-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span.name {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: bold;

    max-width: ${props => (props.table === 'review' ? '100%' : '11rem')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;

    ${media.lessThan('small')`
      max-width: 6rem;
    `}
  }

  span.todo-score {
    font-size: 0.8rem;
    font-weight: 200;
    text-decoration: underline;
    color: green;
  }

  span.frequency {
    font-size: 0.65em;
    cursor: ${props => props.today && 'pointer'};
  }
`;

export const CategoryOrFrequency = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
`;

export const EditButton = styled.span`
  margin-left: 0.2rem;

  border-radius: 50%;
  width: 0.9rem;
  height: 0.9rem;
  font-size: 0.5rem;
  background-color: #d8e2ef;

  filter: ${props => props.editing && 'brightness(0.9)'};

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }

  ${media.lessThan('small')`
    display: none;
  `}
`;

export const SampleLabel = styled(Badge)`
  margin-left: 0.2rem;
  font-size: 0.5rem;

  ${media.lessThan('small')`
    display: none;
  `}
`;
