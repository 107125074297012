import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.section`
  width: 100%;
  margin: 4rem 0 4rem;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.lessThan('medium')`
      flex-direction: column;
      margin: 2rem 0 2rem;
    `}
`;

export const GreetingsWrapper = styled.div`
  width: 50%;

  ${media.lessThan('medium')`
      width: 100%;
      margin-bottom: 0.5rem;
    `}
`;

export const CardsWrapper = styled.div`
  width: 50%;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${media.lessThan('large')`

      flex-direction: column;
    `}

  ${media.lessThan('medium')`
      width: 100%;

    `}
`;
