import styled from 'styled-components';
import { Input } from 'reactstrap';

export const ReviewContainer = styled.div`
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;

  width: 45rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  border: 2px dashed #ccc;

  &.notActive {
    background: transparent;
    box-shadow: none;
  }
`;

export const ScoreLabel = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0 1rem;
`;

export const Suggestion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4px;

  p {
    margin: 0;
    font-size: 0.7rem;

    &:last-child {
      font-weight: bold;
    }
  }
`;

export const ReviewInput = styled(Input)`
  width: 25%;
  margin: 0.5rem;
  font-size: 0.65rem;

  &:last-child {
    margin-right: 1rem;
  }
`;
