import styled from 'styled-components';
import media from 'styled-media-query';

export const ChannelInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
`;

export const ChannelInfoStatus = styled.span`
  font-size: 12px;
`;

export const NewChatButton = styled.button`
  font-size: 24px;
  font-weight: bold;
  visibility: hidden;
  outline: none;
  border: none;
  position: absolute;
  right: 20px;
  background: transparent;

  ${media.lessThan('960px')`{
    visibility:visible;
  }`}
`;
