import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Form, Row } from 'reactstrap';
import api from '../../services/api';
import FalconCardHeader from '../common/FalconCardHeader';
import Flex from '../common/Flex';
import FormGroupInput from '../common/FormGroupInput';

export default function SettingsChangePassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();

    if (newPassword !== confirmPassword)
      return toast.error(`Fields "New Password" and "Confirm New Password" don't match.`);

    if (newPassword.length < 5) return toast.error(`Minimium of 5 characters.`);

    try {
      await api.put('/userpw', { password: newPassword });
      toast.success('Password updated with success.');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      toast.error('Error updating password.');
      console.log(err);
    }
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Change password" light={false} />
      <CardBody className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FormGroupInput
                id="new-password"
                label="New Password"
                value={newPassword}
                onChange={({ target }) => setNewPassword(target.value)}
                type="password"
              />
            </Col>
            <Col xs={12}>
              <FormGroupInput
                id="confirm-password"
                label="Confirm New Password"
                value={confirmPassword}
                onChange={({ target }) => setConfirmPassword(target.value)}
                type="password"
              />
            </Col>
            <Col tag={Flex} xs={12} justify="end">
              <Button color="primary" disabled={newPassword === '' || confirmPassword === ''}>
                Change Password
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
