import React, { Fragment } from 'react';
import { useAuth } from '../../context/AuthContext';
import HalfHalfLayout from '../../layouts/HalfHalfLayout';
import SettingsNotifications from './SettingsNotifications';
import SettingsProfile from './SettingsProfile';
import SettingsPhone from './SettingsPhone';
import SettingsTracker from './SettingsTracker';
import SettingsChangePassword from './SettingsChangePassword';
import SettingsDelete from './SettingsDelete';

const SettingsAside = ({ user }) => (
  <Fragment>
    {/* <SettingsBilling /> */}
    <SettingsTracker user={user} />
    <SettingsChangePassword />
    <SettingsDelete />
  </Fragment>
);

const SettingsContent = ({ user }) => (
  <Fragment>
    <SettingsProfile user={user} />
    <SettingsPhone user={user} />
    <SettingsNotifications user={user} />
  </Fragment>
);

const Settings = () => {
  const { user } = useAuth();

  return (
    <HalfHalfLayout right={<SettingsAside user={user} />}>
      <SettingsContent user={user} />
    </HalfHalfLayout>
  );
};

export default Settings;
