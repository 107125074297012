import React from 'react';
import * as S from './styled';

const StreakLine = ({ streak, streakInitialIndex }) => (
  <S.Container
    width={77 * streak}
    ml={streakInitialIndex === 0 ? streakInitialIndex + streak + 2 : streakInitialIndex * 80 + streak + 2}
  >
    <S.Text>{`${streak}x streak`}</S.Text>
    <S.LineContainer>
      <S.Dot ml={5} />
      <S.Line />
      <S.Dot mr={5} />
    </S.LineContainer>
  </S.Container>
);

const marginsToIndex = {
  0: 160,
  1: 80,
  2: 0,
  3: 0,
};

const MiniStreakLine = ({ streak, streakInitialIndex, streakToDraw }) => {
  let marginLeft = null;

  if (streakToDraw + streakInitialIndex >= 4) {
    marginLeft = 0;
  } else if (streakToDraw + streakInitialIndex === 3) {
    marginLeft = marginsToIndex[1];
  } else {
    marginLeft = marginsToIndex[streakInitialIndex];
  }

  return (
    <S.Container width={77 * streakToDraw} ml={marginLeft}>
      <S.Text>{`${streak}x streak`}</S.Text>
      <S.LineContainer>
        {streakInitialIndex + streak <= 4 && <S.Dot ml={5} />}
        <S.Line />
        <S.Dot mr={5} />
      </S.LineContainer>
    </S.Container>
  );
};

export const StreakIndicator = ({ scores, miniTable, lastWeekIndex }) => {
  /*
  0 - 0 - 0 is not a streak
  0 - 0 - 1 (1x streak)
  1 - 1- 1 (3x streak)
  1 - 2 - 2 (3x streak)
  1 - 2- 3 (3x streak)
*/

  const prepareStreaksForAdvanced = scores => {
    const streaks = [];

    let streak = 1;

    let streakInitialIndex = 0;

    for (let i = 1; i < scores.length; i++) {
      const currentScore = scores[i];
      const prevScore = scores[i - 1];
      if (currentScore >= prevScore) {
        if (currentScore > 0 && prevScore >= 0) {
          if (streak === 1) streakInitialIndex = i - 1;
          streak = prevScore <= 0 ? streak : streak + 1;
        }
        if (streak > 1 && i === scores.length - 1) {
          streaks.push(<StreakLine key={i} streak={streak} streakInitialIndex={streakInitialIndex} />);
        }
      } else {
        if (streak > 1) {
          streaks.push(<StreakLine key={i} streak={streak} streakInitialIndex={streakInitialIndex} />);
        }
        streak = 1;
      }
    }

    return streaks.map(streak => streak);
  };

  const getStreakToDraw = (streakInitialIndex, streak) => {
    if (streakInitialIndex === 0) {
      return streak > 4 ? 4 : streak;
    } else {
      const calculated = 4 - streakInitialIndex > streak ? streak : 4 - streakInitialIndex;
      return 3 - streakInitialIndex > 0 ? calculated : 1;
    }
  };

  const prepareStreaksForMini = scores => {
    const streaks = [];

    let streak = 1;

    let streakInitialIndex = 0;

    for (let i = 1; i < scores.length; i++) {
      const currentScore = scores[i];
      const prevScore = scores[i - 1];
      if (prevScore >= currentScore && prevScore >= 0 && currentScore > 0) {
        if (streak === 1) streakInitialIndex = i - 1;
        streak++;
        if (streak > 1 && i === scores.length - 1) {
          let streakToDraw = getStreakToDraw(streakInitialIndex, streak);
          if (streakInitialIndex <= 3) {
            streaks.push(
              <MiniStreakLine
                key={i}
                streak={streak}
                streakToDraw={streakToDraw}
                streakInitialIndex={streakInitialIndex}
              />
            );
          }
        }
      } else {
        if (streak > 1) {
          let streakToDraw = getStreakToDraw(streakInitialIndex, streak);
          if (streakInitialIndex <= 3) {
            streaks.push(
              <MiniStreakLine
                key={i}
                streak={streak}
                streakToDraw={streakToDraw}
                streakInitialIndex={streakInitialIndex}
              />
            );
          }
        }
        streak = 1;
      }
    }
    return streaks.map(streak => streak);
  };

  return miniTable ? prepareStreaksForMini(scores) : prepareStreaksForAdvanced(scores);
};
