import React from 'react';
import { Button } from 'reactstrap';

export default function PartnerMessage({ habit }) {
  const handleClick = () => {
    window.location.href = `/messages/${habit.friend_id}`;
  };

  return (
    <Button
      id={'friendshabit' + habit.id}
      size="sm"
      icon="plus"
      transform="shrink-6"
      onClick={handleClick}
      className="ml-2 fs--2 btn-partner"
    >
      Send Message
    </Button>
  );
}
