import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChrome } from '@fortawesome/free-brands-svg-icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { useAuth } from '../../context/AuthContext';
import Avatar from '../common/Avatar';

const ProfileDropdown = () => {
  const { user, logout } = useAuth();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [closingDropdown, setClosingdropdown] = useState(false);
  const [closingTimeout, setClosingtimeout] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleDropdown = state => {
    if (state === true) {
      setDropdownOpen(true);
    }

    setClosingdropdown(state);
  };

  useEffect(() => {
    if (closingTimeout) {
      clearInterval(closingTimeout);
    }

    setClosingtimeout(
      setTimeout(() => {
        setDropdownOpen(closingDropdown);
      }, 350)
    );
  }, [closingDropdown]);

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && handleDropdown(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && handleDropdown(false);
      }}
      className="hi-rafa-im-here"
    >
      <DropdownToggle nav className="pr-0" id="btn-account-menu">
        <Avatar name={user.name} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem
            className="font-weight-bold"
            tag="a"
            href="https://chrome.google.com/webstore/detail/weekup/cgcbkfhhcnajodpjddhpipljimehlnlp?gclid=CjwKCAjwqIiFBhAHEiwANg9szuT_RMZQPqUDjDqigDuUz8ia2T0d78kMckv3wSfEwEJP7CzyW--9mhoCfPcQAvD_BwE"
            target="_blank"
            style={{
              color: '#1a73e8',
            }}
          >
            <FontAwesomeIcon icon={faChrome} className="mr-1" />
            <span>Extension</span>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/plans" onClick={() => window.analytics.track(`Clicked on "Subscription"`)}>
            Subscription
          </DropdownItem>
          <DropdownItem tag={Link} to="/settings" onClick={() => window.analytics.track(`Clicked on "Settings"`)}>
            Settings
          </DropdownItem>
          <DropdownItem onClick={logout}>Logout</DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
