import React, { useState } from 'react';
import { toast } from 'react-toastify';

import 'react-phone-input-2/lib/bootstrap.css';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import * as S from './styled';

import Avatar from '../../common/Avatar';
import ButtonIcon from '../../common/ButtonIcon';
import api from '../../../services/api';

import { useData } from '../../../context/DataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingIndicator } from 'stream-chat-react';

const PartnerModal = ({ habit, isOpen, toggleOpen }) => {
  const [name, setName] = useState('');

  const [loading, setLoading] = useState(false);

  const [inviteLink, setInviteLink] = useState('');
  const [inviteText, setInviteText] = useState('');

  const generateInviteLink = async () => {
    try {
      if (name === '') {
        toast.error('Please enter a name', { position: 'top-center' });
        return;
      }
      setLoading(true);
      const res = await api.post('/new-partner', {
        invited: {
          name,
          email: 'mobileinvite@weekup.io',
        },
        selected: false,
        habit_id: habit.id,
        habit_name: habit.name,
      });
      setInviteLink(`https://weekup.io/partner?partnerToken=${res.data.token}`);

      setInviteText(`Hey ${name}, I added you as an accountability partner to help me stick with my habit ${
        habit.name
      }. Please
      accept the invite here: https://weekup.io/partner?partnerToken=${res.data.token}`);
      setLoading(false);
      toast.success('Generated invite link!', { position: 'top-center' });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleOpen} centered>
      <ModalHeader>Add Accountability Partner</ModalHeader>
      <ModalBody>
        {inviteLink === '' ? (
          <FormGroup>
            <Label for="partner-name">Accountability Partner's Name</Label>
            <Input
              required
              type="text"
              name="name"
              id="partner-name"
              placeholder="Your friend's name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </FormGroup>
        ) : (
          <FormGroup>
            <Label style={{ color: 'red' }} for="partner-link">
              <FontAwesomeIcon icon="exclamation-triangle" style={{ marginRight: 4 }} />
              This is a one-time private link you can send to <b>{name}</b> and you won't be able to see it again. Don't
              share publicly.
            </Label>
            <S.Container>
              <S.InviteLink>{inviteText}</S.InviteLink>
              <S.ShareButton
                onClick={() =>
                  navigator.clipboard.writeText(inviteText).then(() => toast.success('Copied to clipboard!'))
                }
              >
                <FontAwesomeIcon icon="copy" color="#748194" size={20} />
              </S.ShareButton>
            </S.Container>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          style={{ width: 152, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          disabled={loading}
          onClick={() =>
            inviteLink === ''
              ? generateInviteLink()
              : navigator.clipboard.writeText(inviteText).then(() => toast.success('Copied to clipboard!'))
          }
        >
          {loading ? <LoadingIndicator size={24} color="white" /> : inviteLink === '' ? 'Get invite code' : 'Copy Link'}
        </Button>
        <Button onClick={toggleOpen}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

const RemovePartner = ({ habit, isOpen, toggleOpen }) => {
  const { updateData } = useData();
  const [loading, setLoading] = useState(false);

  const handleRemovePartner = async () => {
    setLoading(true);
    try {
      await api.post('/remove-partner', {
        habit_id: habit.id,
        partner_id: null,
      });
      updateData('updateHabit', {
        ...habit,
        partner_id: null,
        partner_name: null,
        partner_email: null,
      });
      setLoading(false);
      toast.success('Partner removed!');
      window.analytics.track('Removed accountability partner');
      toggleOpen();
    } catch (err) {
      setLoading(false);
      toast.error('Error!', { position: 'top-center' });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleOpen} centered>
      <ModalHeader>Do you want to remove this Accountability Partner?</ModalHeader>
      <ModalBody>
        You're removing {habit.friend_name ? habit.friend_name : habit.partner_name} from {habit.name}.
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggleOpen}>Close</Button>
        <Button type="submit" color="primary" disabled={loading} onClick={handleRemovePartner}>
          Remove Partner
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const AccountabilityPartner = ({ habit }) => {
  const { partner_id, partner_name, partner_user_id, friend_name } = habit;

  const [isOpen, setIsOpen] = useState(false);
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);

  if (!partner_id) {
    return (
      <>
        <ButtonIcon
          size="sm"
          icon="plus"
          onClick={() => setIsOpen(true)}
          disabled={!habit.active}
          className="ml-2 fs--2 btn-partner btn-add-partner"
        >
          Accountability Partner
        </ButtonIcon>
        <PartnerModal isOpen={isOpen} toggleOpen={() => setIsOpen(!isOpen)} habit={habit} />
      </>
    );
  }

  return (
    <div className="d-flex align-items-center cursor-pointer" onClick={() => setIsRemoveOpen(true)}>
      <RemovePartner isOpen={isRemoveOpen} toggleOpen={() => setIsRemoveOpen(!isRemoveOpen)} habit={habit} />
      <Avatar name={friend_name ? friend_name : partner_name} size="xl" className="ml-2 mr-2" />
      <div className="d-flex flex-column">
        {friend_name ? friend_name : partner_name}
        {!partner_user_id ? <span className="text-danger small font-weight-bold">Pending</span> : null}
      </div>
    </div>
  );
};

export default AccountabilityPartner;
