import React, { useState, useEffect, useContext, createContext } from 'react';
import axios from 'axios';
import api from '../services/api';

import { useAuth } from './AuthContext';
import { parseDateInfo } from '../helpers/tracker-parsers/parsers';

const DataContext = createContext();

export const useData = () => {
  const context = useContext(DataContext);

  return context;
};

export const DataProvider = ({ children }) => {
  const { user } = useAuth();
  const [data, setData] = useState({
    reviews: [],
    logs: [],
    habits: [],
    categories: [],
    date: null,
  });
  const [partnerData, setPartnerData] = useState({ reviews: [], habits: [], categories: [], date: {}, scores: [] });
  const [loading, setLoading] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (user === null) {
      setData({
        reviews: [],
        logs: [],
        habits: [],
        categories: [],
        date: null,
      });
      setPartnerData({ reviews: [], habits: [], categories: [], date: {}, scores: [] });
    }
  }, [user]);

  const updateData = (method, payload, extraData) => {
    let updatedData;

    if (method === 'addReviews') {
      const reviewsCopy = [...data.reviews, ...payload];
      updatedData = { ...data, reviews: reviewsCopy };
      updatedData = { ...updatedData, date: parseDateInfo(updatedData, user.tracker_settings) };
    }

    if (method === 'newHabit') {
      const habitsCopy = [...data.habits];
      const reviewsCopy = [...data.reviews, ...extraData];
      habitsCopy.push(payload);
      updatedData = { ...data, habits: habitsCopy, reviews: reviewsCopy };
      updatedData = { ...updatedData, date: parseDateInfo(updatedData, user.tracker_settings) };
    }

    if (method === 'deleteHabit') {
      const habitsCopy = [...data.habits];
      const habitIndex = habitsCopy.findIndex(h => h.id === payload);
      habitsCopy.splice(habitIndex, 1);
      const reviewsCopy = [...data.reviews];
      const reviewsWithoutHabit = reviewsCopy.filter(r => r.habit_id !== payload);
      updatedData = { ...data, habits: habitsCopy, reviews: reviewsWithoutHabit };
      updatedData = {
        ...updatedData,
        date: habitsCopy.length > 0 ? parseDateInfo(updatedData, user.tracker_settings) : null,
      };
    }

    if (method === 'deleteAllSample') {
      const notSampleReviews = data.reviews.filter(r => {
        const habitFromReview = data.habits.find(h => h.id === r.habit_id);
        if (!habitFromReview.sample) {
          return r;
        }
      });
      updatedData = { ...data, habits: payload, reviews: notSampleReviews };
      updatedData = {
        ...updatedData,
        date: payload.length > 0 ? parseDateInfo(updatedData, user.tracker_settings) : null,
      };
    }

    if (method === 'updateHabit') {
      const habitsCopy = [...data.habits];
      const habitIndex = habitsCopy.findIndex(h => h.id === payload.id);
      habitsCopy.splice(habitIndex, 1, payload);
      updatedData = { ...data, habits: habitsCopy };
      updatedData = { ...updatedData, date: parseDateInfo(updatedData, user.tracker_settings) };
    }

    if (method === 'updateScore') {
      const reviewIndex = data.reviews.findIndex(r => r.id === extraData.id);
      const toggledReview = { ...extraData, score: payload };
      const reviewsCopy = [...data.reviews];
      reviewsCopy.splice(reviewIndex, 1, toggledReview);
      updatedData = { ...data, reviews: reviewsCopy };
      updatedData = { ...updatedData, date: parseDateInfo(updatedData, user.tracker_settings) };
    }

    if (method === 'updateReflection') {
      const reviewIndex = data.reviews.findIndex(r => r.id === extraData.id);
      const toggledReview = { ...extraData, ...payload };
      const reviewsCopy = [...data.reviews];
      reviewsCopy.splice(reviewIndex, 1, toggledReview);
      updatedData = { ...data, reviews: reviewsCopy };
      updatedData = { ...updatedData, date: parseDateInfo(updatedData, user.tracker_settings) };
    }

    if (method === 'addLog') {
      const logsCopy = [...data.logs];
      logsCopy.push(payload);
      updatedData = { ...data, logs: logsCopy };
      updatedData = { ...updatedData, date: parseDateInfo(updatedData, user.tracker_settings) };
    }

    if (method === 'updateLog') {
      const logsCopy = [...data.logs];
      const logIndex = data.logs.findIndex(l => l.id === payload.id);
      logsCopy.splice(logIndex, 1, payload);
      updatedData = { ...data, logs: logsCopy };
      updatedData = { ...updatedData, date: parseDateInfo(updatedData, user.tracker_settings) };
    }

    setData(updatedData);
  };

  const getData = async () => {
    const { data: responseData } = await api.get('/reviews');

    if (!responseData.habits.length) {
      setData({ ...responseData, categories: responseData.categories });
      setLoading(false);
      return;
    }

    setData({
      ...responseData,
      date: parseDateInfo(responseData, user.tracker_settings),
    });

    setLoading(false);
  };

  const getPartnerData = async () => {
    const { data: responseData } = await api.get('/partner-habits');

    setPartnerData({
      ...responseData,
      date: parseDateInfo(responseData, null),
    });

    setData({
      ...data,
      categories: responseData.categories,
    });

    setLoading(false);
  };

  const getAllData = async () => {
    setLoading(true);

    const dataRequest = api.get('/reviews');
    const partnerRequest = api.get('/partner-habits');

    axios
      .all([dataRequest, partnerRequest])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0].data;
          const responseTwo = responses[1].data;

          setData({
            ...responseOne,
            date: parseDateInfo(responseOne, user.tracker_settings),
          });

          setPartnerData({
            ...responseTwo,
            date: parseDateInfo(responseTwo, null),
          });

          setLoading(false);
        })
      )
      .catch(errors => {
        console.error(errors);
      });
  };

  useEffect(() => {
    const defineDataCall = () => {
      if (user.tracker_settings && user.isPartner && user.hasHabits) {
        getAllData();
      } else if (user.onlyPartner) {
        getPartnerData();
      } else if (user.tracker_settings) {
        getData();
      }
    };

    user && defineDataCall();
    // eslint-disable-next-line
  }, [user]);

  return (
    <DataContext.Provider
      value={{
        loading,
        data,
        partnerData,
        updateData,
        getData,
        unreadCount,
        setUnreadCount,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
