import styled from 'styled-components';
import media from 'styled-media-query';
import { Button } from 'reactstrap';

export const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 300px;
  max-width: 100vw;

  position: relative;

  &:after {
    display: ${props => (props.privacyMode ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 20px;
    backdrop-filter: blur(4px);
    transform: scale(1.2);
  }

  p {
    font-style: italic;
  }
`;

export const DisablePrivacyMode = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px 0px,
    rgba(0, 0, 0, 0.4) 0px 15px 40px 0px;
`;

export const SubtitlesContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  h5#weeks-subtitle {
    min-width: 20rem;
    text-align: left;
    font-weight: 200;
    margin-bottom: 0;

    ${media.lessThan('small')`
      display: none;
    `}
  }
`;

export const HabitsSubtitle = styled.h5`
  min-width: 14rem;
  width: ${props => (props.table === 'review' ? '16rem' : '100%')};
  padding-left: 0.5rem;
  font-weight: 200;
  margin-bottom: 0;
  font-size: 1.5rem;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  padding: 0.25rem 0;
  font-weight: 600;
  color: #344050;
  min-height: 1.5rem;

  div.weeks {
    display: flex;
    align-items: center;
    font-size: 0.6rem;
    margin-left: ${props => (props.table === 'review' ? '14rem' : 'auto')};

    span {
      width: 5rem;
      text-align: center;
    }

    ${media.lessThan('small')`
      display: none;
    `}
  }

  div.reviews {
    width: 100%;
    margin-left: 2rem;
    padding-left: 5rem;
    font-size: 0.65rem;
  }
`;

export const Row = styled.div`
  display: flex;

  border-radius: 25px;
  margin: 0 0 0.5rem 0.5rem;

  background: white;

  div.frequency {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 10rem;
    font-size: 0.8rem;
    color: 'grey';

    ${media.lessThan('large')`
    display: none;
  `}
  }

  div.category {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 10rem;

    ${media.lessThan('large')`
    display: none;
  `}
  }

  div.status {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 12rem;
    min-height: 3.5rem;

    ${media.lessThan('small')`
    padding-left: 0.5rem;
   
    width: auto;
min-width: 50%;
max-width: 50%;
    `}
  }

  div.partner {
    display: flex;
    align-items: center;

    width: 16rem;
    min-height: 3.5rem;

    ${media.lessThan('large')`
      display: none;
    `}
  }

  div.scores {
    display: flex;
    margin-left: auto;
    border-left: 15px solid #f4f4f4;

    ${media.lessThan('small')`
      display: none;
    `}
  }
`;
