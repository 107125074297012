import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';

const HalfHalfLayout = ({ banner, right, footer, isStickyAside, children }) => {
  return (
    <Fragment>
      {banner}
      <Row noGutters>
        <Col lg="6" className={classNames('pr-lg-2', { 'mb-3': !isStickyAside })}>
          {children}
        </Col>
        <Col lg="6" className={classNames('pl-lg-2', { 'mb-3': !isStickyAside })}>
          {isStickyAside ? <div className="sticky-top">{right}</div> : right}
        </Col>
      </Row>
      {footer}
    </Fragment>
  );
};

export default HalfHalfLayout;
