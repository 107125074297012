import React from 'react';
import * as S from './styled';

export const EmptyChannelContent = () => {
  return (
    <S.ContentWrapper>
      <div>Welcome to Weekup! Find your contacts and start chatting!</div>
    </S.ContentWrapper>
  );
};
