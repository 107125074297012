export const durationOptions = [
  {
    name: '1min',
    value: 1,
  },
  {
    name: '5min',
    value: 5,
  },
  {
    name: '10min',
    value: 10,
  },
  {
    name: '15min',
    value: 15,
  },
  {
    name: '20min',
    value: 20,
  },
  {
    name: '30min',
    value: 30,
  },
  {
    name: '45min',
    value: 45,
  },
  {
    name: '1hr',
    value: 60,
  },
  {
    name: '1.5hr',
    value: 90,
  },
  {
    name: '2hr',
    value: 120,
  },
  {
    name: '2.5hr',
    value: 150,
  },
  {
    name: '3hr',
    value: 180,
  },
  {
    name: 'Not applicable',
    value: 0,
  },
];
