import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  margin-right: 2rem;

  ${media.lessThan('large')`
      margin-right: 0;
      margin-left: auto;
    `}

  ${media.lessThan('medium')`
      margin-right: auto;
      margin-left: 0;

    `}
`;

export const Score = styled.div`
  border-radius: 25px;
  padding: 0.7rem;
  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 0.75rem;

  h3 {
    font-size: 2.5rem;
    font-weight: 600;

    margin: 0;
  }
`;

export const Caption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  font-size: 0.8rem;
  color: grey;

  div.score {
    span {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
`;
