import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import { toast } from 'react-toastify';

import FalconCardHeader from '../common/FalconCardHeader';
import api from '../../services/api';

import { useAuth } from '../../context/AuthContext';

export default function SettingsDelete() {
  const { logout } = useAuth();

  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteAccount = async () => {
    setIsLoading(true);

    try {
      await api.delete('/user');
      toast.info('Account deleted');
      logout();
    } catch (err) {
      toast.info('Error deleting account. Please contact support.');
    }

    setIsLoading(false);
  };

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="Delete account" light={false} />
        <CardBody className="bg-light">
          <Row>
            <Col xs={12} className="fs--1 mb-2 font-weight-bold">
              This action is irreversible. We'll delete all data from your account.
            </Col>
            <Col xs={12} onClick={() => setIsPopupOpened(true)}>
              <Button>Delete my account</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Modal isOpen={isPopupOpened} toggle={() => setIsPopupOpened(!isPopupOpened)}>
        <ModalHeader toggle={() => setIsPopupOpened(!isPopupOpened)}>Are you sure?</ModalHeader>
        <ModalBody>This action is irreversible. We'll delete all data from your account.</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setIsPopupOpened(!isPopupOpened)} disabled={isLoading}>
            Cancel
          </Button>
          <Button color="secondary" onClick={handleDeleteAccount} disabled={isLoading}>
            Yes, I'm sure
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
