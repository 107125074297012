import styled from 'styled-components';
import { DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DropdownButton = styled(DropdownToggle)`
  min-width: 100%;
  display: flex;
  align-items: center;

  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const DropdownIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
`;

export const Subtitle = styled.h5`
  font-size: 0.8rem;
  font-weight: bold;
`;

export const WeekDayBall = styled.span`
  line-height: 0;
  height: 1.5rem;
  width: 1.5rem;

  margin-right: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  cursor: pointer;

  color: ${props => (props.active ? '#fafafa' : '#070707')};
  background: ${props => (props.active ? '#2c7be5' : '#37352f14')};

  &:last-child {
    margin-right: 0;
  }
`;
