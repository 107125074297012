import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  min-width: ${props => (props.isForReviewTable ? '15rem' : '5rem')};
  text-align: center;
  font-size: 0.75rem;
  padding-top: 0.25rem;

  &:first-child {
    margin-left: ${props => props.table === 'review' && '14rem'};
  }

  ${media.lessThan('large')`
      display: ${props => (props.isForReviewTable || props.table !== 'review' ? 'block' : 'none')};
    `}

  ${media.lessThan('small')`
      display: none;
    `}
`;

export const Score = styled.div`
  span {
    font-size: 1.5em;
    font-weight: 600;
  }
`;

export const Improvement = styled.div`
  font-weight: 600;
`;
