import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Form, Row } from 'reactstrap';
import { toast } from 'react-toastify';

import { useAuth } from '../../context/AuthContext';
import api from '../../services/api';

import FalconCardHeader from '../common/FalconCardHeader';
import FormGroupInput from '../common/FormGroupInput';
import Flex from '../common/Flex';

import { generateCodeFromUserId } from '../../helpers/utils';

const SettingsProfile = ({ user }) => {
  const { updateUser } = useAuth();

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);

  const handleProfileSettings = async e => {
    e.preventDefault();

    if (name.trim().indexOf(' ') === -1) {
      toast.info(`The last name is also required.`);
      return;
    }

    try {
      await api.patch('/user', {
        name,
      });
      updateUser('name', name);
      toast.success('Success.');
    } catch (err) {
      toast.error('There is an error in your request.', { position: 'top-center' });
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(generateCodeFromUserId(user.id));
    toast.success('Copied Successfully');
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Profile Settings" light={false} />
      <CardBody className="bg-light">
        <Form onSubmit={handleProfileSettings}>
          <Row>
            <Col xs={12}>
              <FormGroupInput id="name" label="Name" value={name} onChange={({ target }) => setName(target.value)} />
            </Col>
            <Col xs={12}>
              <FormGroupInput id="email" label="Email" value={email} type="email" disabled />
            </Col>

            <Col xs={12}>
              <label>Profile ID</label>
              <div className="d-flex align-items-center">
                <span className="mr-2 fs-1 text-900 font-weight-bold">{generateCodeFromUserId(user.id)}</span>
                <Button size="sm" className="py-1" onClick={handleCopyClick}>
                  copy
                </Button>
              </div>
            </Col>

            <Col tag={Flex} xs={12} justify="end">
              <Button color="primary" type="submit">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default SettingsProfile;
