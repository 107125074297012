import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Spinner } from 'reactstrap';
import { useAuth } from '../../context/AuthContext';
import { useData } from '../../context/DataContext';
import api from '../../services/api';
import * as S from './styled';

export default function GuideBanner() {
  const { user } = useAuth();
  const { loading, data, updateData } = useData();

  const [isDeleteSampleLoading, setIsDeleteSampleLoading] = useState(false);

  const settings = user ? JSON.parse(user.tracker_settings) : false;

  const isThereSampleHabits = data.habits.findIndex(h => h.sample) === -1 ? false : true;

  const handleDeleteSample = async () => {
    setIsDeleteSampleLoading(true);
    const notSampleHabits = data.habits.filter(h => !h.sample);
    const allSampleHabits = data.habits.filter(h => h.sample);
    for (const el of allSampleHabits) {
      try {
        await api.delete('/habit', { data: { id: el.id } });
      } catch (err) {
        toast.error('Error', { position: 'top-center' });
      }
    }
    window.analytics.track('Deleted all sample habits');
    updateData('deleteAllSample', notSampleHabits);
    setIsDeleteSampleLoading(false);
  };

  if (loading) {
    return null;
  }

  if (isThereSampleHabits) {
    return (
      <S.Wrapper bg="#FEF7F6" id="fixed-guide-banner" className="text-1100">
        <span>⛔️</span> Ready to start adding habits?{' '}
        <button onClick={handleDeleteSample} disabled={isDeleteSampleLoading}>
          Delete sample habits
        </button>{' '}
        {isDeleteSampleLoading && <Spinner className="ml-2" size="sm" color="secondary" />}
      </S.Wrapper>
    );
  }

  const hasNormalHabit = data.habits.findIndex(h => !h.sample) === -1 ? false : true;

  if (!hasNormalHabit && !settings.alreadyCreatedHabit) {
    return (
      <S.Wrapper bg="#F9F9FA" id="fixed-guide-banner" className=" text-1100">
        <span>🎯</span> Awesome! Now let's create your first habit
      </S.Wrapper>
    );
  }

  const isReflectionThisWeek = Boolean(
    data.habits.filter(h => {
      const currentWeekYear = Number(moment().format('WW'));

      const createdAt = moment(h.created_at);
      const dayCreated = Number(createdAt.format('E'));
      const weekCreated = Number(createdAt.format('WW'));

      const createdOnFirstDays = dayCreated === 1 || dayCreated === 2;

      if (createdOnFirstDays || currentWeekYear !== weekCreated) {
        return true;
      }
    }).length
  );

  if (!settings.alreadyFilledReflection && !settings.lastFilledReflection) {
    return (
      <S.Wrapper bg="#FEF7F7" id="fixed-guide-banner" className=" text-1100">
        <span>💡</span> <strong className="mr-1">Now what?</strong> Once you've added all your habits, we'll ask you to
        reflect on them end of {isReflectionThisWeek ? 'this week' : 'next week'}
      </S.Wrapper>
    );
  }

  return null;
}
