import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useData } from '../../context/DataContext';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import Cookies from 'js-cookie';

export const PartnerInviteModal = ({ isOpen, toggleOpen, invite }) => {
  const { habit_id, habit_name, token, inviter } = invite;

  const { partnerData, setPartnerData } = useData();

  const { setInvite } = useAuth();

  const [loading, setLoading] = useState('');

  const acceptInvite = async () => {
    try {
      setLoading('accept');
      await api.get(`/match-partner?token=${token}`);
      setInvite(null);
      Cookies.remove('invite', { domain: '.weekup.io' });
      setLoading(null);
      toast.success('Invite accepted!');
      toggleOpen();
    } catch (e) {
      setLoading(null);
      toggleOpen();
      toast.error('Something went wrong');
    }
  };

  const declineInvite = async () => {
    try {
      setLoading('decline');
      const habit = partnerData?.habits.find(h => h.id === habit_id);
      if (habit) {
        const newPartnerData = {
          ...partnerData,
          habits: partnerData.habits.filter(h => h.id !== habit_id),
        };
        setPartnerData({ newPartnerData });
      }
      await api.post('/remove-partner', {
        habit_id,
        partner_id: null,
      });
      setInvite(null);
      Cookies.remove('invite', { domain: '.weekup.io' });
      setLoading(null);
      toast.success('Invite declined!');
      toggleOpen();
    } catch (e) {
      setLoading(null);
      toggleOpen();
      toast.error('Something went wrong');
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleOpen} centered>
      <ModalHeader>Partner Invitation</ModalHeader>
      <ModalBody>
        {inviter} has invited you to be a partner to habit: {habit_name}. Will you accept it?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={loading === 'accept'} onClick={acceptInvite}>
          Accept
        </Button>
        <Button color="danger" disabled={loading === 'decline'} type="submit" onClick={declineInvite}>
          Decline
        </Button>
      </ModalFooter>
    </Modal>
  );
};
