import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import DashboardLayout from './DashboardLayout';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ component, ...rest }) => {
  const { signed } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return signed ? (
          <DashboardLayout component={component} />
        ) : (
          <Redirect
            to={{
              pathname: '/authentication/login',
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
