import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import { useAuth } from '../../context/AuthContext';

import FormGroupInput from '../common/FormGroupInput';
import FalconCardHeader from '../common/FalconCardHeader';
import Flex from '../common/Flex';
import api from '../../services/api';

export default function SettingsPhone({ user }) {
  const { updateUser } = useAuth();

  const [phone, setPhone] = useState(user.phone);
  const [code, setCode] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleVerifyPhone = async () => {
    setIsLoading(true);
    try {
      await api.post('/verify-phone', { phone: `+${phone}` });
      setIsModalOpen(true);
      toast.info('Check your phone...');
    } catch (err) {
      toast.error('There was an error with the phone number you provided. Please verify and try it again', {
        position: 'top-center',
      });
    }
    setIsLoading(false);
  };

  const handleVerifyCode = async () => {
    setIsLoading(true);
    try {
      await api.post('/verify-code', { phone: `+${phone}`, code });
      setIsModalOpen(false);
      updateUser('phone', phone);
      toast.success('Verified.');
      window.analytics.track('Updated phone');
    } catch (err) {
      toast.error('There was an error in your verification.', {
        position: 'top-center',
      });
    }
    setIsLoading(false);
  };

  const handleDeletePhone = async () => {
    setIsLoading(true);
    try {
      await api.patch('/user', {
        phone: null,
      });
      updateUser('phone', null);
      setPhone('');
      toast.info('Number removed.');
      window.analytics.track('Removed phone');
    } catch (err) {
      toast.error('There was an error in your requrest.', {
        position: 'top-center',
      });
    }
    setIsLoading(false);
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader
        title={
          <div>
            Phone Verification
            {Boolean(user.phone) ? (
              <Badge color={'soft-success'} className="ml-2" pill>
                verified
              </Badge>
            ) : (
              ''
            )}
          </div>
        }
        light={false}
      />
      <CardBody className="bg-light">
        <Row>
          <Col xs={12}>
            <PhoneInput
              name="phone"
              id="phone"
              value={phone}
              country="us"
              onChange={phone => setPhone(phone)}
              type="tel"
              inputStyle={{ width: '100%', marginBottom: '1rem' }}
              disabled={Boolean(user.phone)}
            />
          </Col>

          <Col tag={Flex} xs={12} justify="end">
            {Boolean(user.phone) && (
              <Button color="danger" className="mr-1" onClick={handleDeletePhone} disabled={isLoading}>
                Remove Number
              </Button>
            )}
            <Button
              id="btn-verify-number"
              color="primary"
              onClick={handleVerifyPhone}
              disabled={Boolean(user.phone) || isLoading}
            >
              Verify Number
            </Button>
          </Col>
        </Row>
      </CardBody>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Verify Code</ModalHeader>
        <ModalBody>
          <FormGroupInput
            name="code"
            id="code"
            value={code}
            label="Code"
            onChange={({ target }) => setCode(target.value)}
            maxLength="6"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsModalOpen(!isModalOpen)}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleVerifyCode} disabled={isLoading}>
            Confirm
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </Card>
  );
}
