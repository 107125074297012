import React, { useEffect, useState } from 'react';
import { Card, CardBody, CustomInput, Collapse, FormGroup, Button, Col } from 'reactstrap';
import { toast } from 'react-toastify';

import FalconCardHeader from '../common/FalconCardHeader';
import api from '../../services/api';

import { useAuth } from '../../context/AuthContext';
import { NotificationCheckbox } from './NotificationCheckbox';

export default function SettingsNotifications({ user }) {
  const { updateUser, notificationPermission, enablePushNotifications } = useAuth();

  const [settings, setSettings] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const userPushNotifications = JSON.parse(user.notification_settings);

  useEffect(() => {
    const trackerSettings = JSON.parse(user.tracker_settings);
    setSettings(trackerSettings);
  }, []);

  const handleOptoutClick = async (name, value) => {
    const trackerSettings = JSON.parse(user.tracker_settings);

    const trackerWithOptout = {
      ...trackerSettings,
      [name]: value,
    };

    setIsLoading(true);

    try {
      await api.patch('/user', {
        tracker_settings: JSON.stringify(trackerWithOptout),
      });
      updateUser('tracker_settings', JSON.stringify(trackerWithOptout));
      setSettings(trackerWithOptout);
      toast.info('Updated with success.');
    } catch (err) {
      toast.error('There was an error in your requrest.', {
        position: 'top-center',
      });
    }
    setIsLoading(false);
  };

  const updateNotificationSettings = async settings => {
    try {
      const res = await api.patch('/user/notificationsettings', { settings });
      return res.data;
    } catch (e) {
      throw new Error(e);
    }
  };

  const updateSettings = async payload => {
    try {
      setIsLoading(true);
      const settings = {
        ...userPushNotifications,
        ...payload,
      };
      updateUser('notification_settings', JSON.stringify(settings));
      await updateNotificationSettings(JSON.stringify(settings));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast.error('Could not update settings.', { position: 'top-center' });
    }
  };

  const { dueReflection, dueFriendReflection, smartAlerts, smartFriendAlerts, newMessages } = userPushNotifications;

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Notification Settings" light={false} />
      <CardBody className="bg-light">
        <FormGroup cssModule={{ padding: 0 }}>
          <h6>Push notifications</h6>
          {notificationPermission === 'granted' ? (
            <>
              <NotificationCheckbox
                id="notificationSettingsNewMessages"
                label="Direct Messages"
                checked={newMessages}
                onChange={() => updateSettings({ newMessages: !newMessages })}
                disabled={isLoading}
              />
              <NotificationCheckbox
                id="notificationSettingsSmartAlerts"
                label="Smart Alerts - for you"
                checked={smartAlerts}
                explanationText="(We send you only a few reminders to remind you of habits that need your attention)"
                onChange={() => updateSettings({ smartAlerts: !smartAlerts })}
                disabled={isLoading}
              />
              <NotificationCheckbox
                id="notificationSettingsSmartFriendAlerts"
                label="Smart Alerts - for friends"
                explanationText="(We send you only a few reminders to remind your friends when some of their habits are at risk)"
                checked={smartFriendAlerts}
                onChange={() => updateSettings({ smartFriendAlerts: !smartFriendAlerts })}
                disabled={isLoading}
              />
              <NotificationCheckbox
                id="notificationSettingsDueReflection"
                label="Reflection due alert - for you"
                explanationText="(We remind you when it’s time to do your weekly reflection)"
                checked={dueReflection}
                onChange={() => updateSettings({ dueReflection: !dueReflection })}
                disabled={isLoading}
              />
              <NotificationCheckbox
                id="notificationSettingsDueFriendReflection"
                label="Reflection due alert - for friends"
                explanationText="(We alert you when your friends have missed their reflection)"
                checked={dueFriendReflection}
                onChange={() => updateSettings({ dueFriendReflection: !dueFriendReflection })}
                disabled={isLoading}
              />
            </>
          ) : (
            <>
              {notificationPermission === 'denied' &&
                'You blocked us from sending you notifications. Please enable them from your browsers site settings to continue.'}
              {notificationPermission === 'default' && (
                <>
                  We need you to allow us to send you notifications.
                  <Button className="mt-2" onClick={() => enablePushNotifications()}>
                    Click here to give us permission.
                  </Button>
                </>
              )}
            </>
          )}
        </FormGroup>
        <FormGroup>
          <h6>Other notifications</h6>
          <CustomInput
            id="customCheckEmailReminderOptout"
            label={'I want to STOP receiving a weekly Email Reminder.'}
            checked={settings?.emailReminderOptout}
            onChange={({ target }) => handleOptoutClick('emailReminderOptout', target.checked)}
            type="checkbox"
            disabled={isLoading}
          />
          <CustomInput
            id="customCheckSMSReminderOptout"
            label={'I want to STOP receiving a weekly SMS Reminder.'}
            checked={settings?.smsReminderOptout}
            onChange={({ target }) => handleOptoutClick('smsReminderOptout', target.checked)}
            type="checkbox"
            disabled={isLoading}
          />
          <CustomInput
            id="customCheckSMSPartnerOptout"
            label={'I want to STOP receiving SMS messages from my Accountability Partners.'}
            checked={settings?.smsMessagesOptout}
            onChange={({ target }) => handleOptoutClick('smsMessagesOptout', target.checked)}
            type="checkbox"
            disabled={isLoading}
          />
        </FormGroup>
      </CardBody>
    </Card>
  );
}
