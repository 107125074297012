import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';

import { useAuth } from './context/AuthContext';
import Loading from './views/Loading';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import '@stream-io/stream-chat-css/dist/css/index.css';
import './views/Messages/MessageView.css';
const App = () => {
  const { loading } = useAuth();

  return <Router basename={process.env.PUBLIC_URL}>{loading ? <Loading /> : <Layout />}</Router>;
};

export default App;
